<div class="row sei-margin-top">
    <div class="col-xs-12"
         *ngFor="let account of getFilteredAccounts(); let i=index">
        <sei-car-proposed-accounts-card *ngIf="accountTypes"
                                        [account]="account"
                                        [proposal]="proposal"
                                        [index]="getAccountIndexForNewProposedAccounts(i)"
                                        [accountTypes]="accountTypes"
                                        [remove403b]="remove403b"
                                        [removeQualifiedPlans]="removeQualifiedPlans"
                                        (onAccountDelete)="onAccountDelete($event)">
        </sei-car-proposed-accounts-card>
    </div>
</div>
