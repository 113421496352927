/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

// import { List, Set } from 'immutable';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { CardDetail, EditDataObject, ErrorDataDetails, NonModelPortfolioTypes, PagingObject, Portfolio, SaveAccountDetails, SubmitDataObject, SubstitutionsCategory, SubstitutionsDetails } from '@sei/ias-applications-lib-ux';
import { OrderedSet } from 'immutable';
import { Observable } from 'rxjs';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { HudSummary, ScenarioAccountFee } from './account-fee';
import { EntitySuffix } from './contact';
import { InvestmentProgram } from './investment-program';
import { QuestionAnswer } from './questionnaire';
import { Firm, User } from './user-profile';

export interface Proposal {
    id: number;
    name?: string;
    advisorId?: number;
    completeRiskQuestionnaire?: boolean;
    completeTaxAnalysis?: boolean;
    scenarios?: Scenario[];
    clients?: Client[];
    firmId: number;
    firm?: Firm;
    riskPreference?: RiskPreference;
    presentation?: Presentation;
    wipCheckList?: WipCheckList[];
    advisorChangeFlag: boolean;
    firmChangeFlag?: boolean;
    advisors?: Advisor[];
    statusId?: number;
}

export interface WipCheckList {
    id: number;
    scenarioId?: number;
    name?: string;
    include?: boolean;
    route?: string;
    sectionType?: string;
    balance?: number;
    isLastVisited?: boolean;
    mdcSnapShot?: MdcSnapshot;
    subCheckList?: WipCheckList[];
}

export interface WipCheckListDisplayModel {
    strategyName: string;
    strategyAmount: number;
    isSubstitutionDetailsClicked?: boolean;
    isStandaloneStrategy: boolean;
    strategySubstitutions?: StrategySubstitutionsList[];
}

export interface StrategySubstitutionsList {
    modelName: string;
    substitutionDetail: string;
}

// MV display

export interface WipCheckListCurrentAccount {
    id: number;
    scenarioId?: number;
    name?: string;
    include?: boolean;
    route?: string;
    sectionType?: string;
    balance?: number;
    isLastVisited?: boolean;
    mdcSnapShot?: MdcSnapshot;
    isSubscribedAssetsIncluded?: boolean;
    isUnsubscribedAssetsIncluded?: boolean;
    priceAsOfDate?: string;
    totalMarketValue?: number;
    subCheckList?: WipCheckList[];
}

export interface WipCheckListCurrentAccountDisplay {
    id: number;
    scenarioId?: number;
    name?: string;
    include?: boolean;
    route?: string;
    sectionType?: string;
    balance?: number;
    isLastVisited?: boolean;
    mdcSnapShot?: MdcSnapshot;
    isSubscribedAssetsIncluded?: boolean;
    isUnsubscribedAssetsIncluded?: boolean;
    priceAsOfDate?: string;
    totalMarketValue?: number;
    marketValueDisplay?: MarketValueDisplay;
    isSaveTaxRatesFailed?: boolean;
    subCheckList?: WipCheckListCurrentAccountDisplay[];
}

export interface MarketValueDisplay {
    value: number;
    actualCurrency: string;
}

// MV display

export interface TaxTransitionEventData {
    loadTaxTransition: boolean;
    addEditButtonText: string;
    showError: boolean;
    showInProcess: boolean;
    isAddEnabled: boolean;
    showSuccess: boolean;
    showSaved: boolean;
    showInfo: boolean;
}

export interface DataObject {
    requestId?: string | number;
    message?: string;
    isInvestmentCalcApiFailed: boolean;
    isTaxRatesApiFailed: boolean;
}

export interface SubmitTaxTransitionResponse {
    data: DataObject;
    paging?: PagingObject;
    error?: ErrorDataDetails;
}

export interface WipFee {
    investmentFee: number;
    fundExp: number;
    mgmtFee: number;
    dFSFee: number;
    platformFee: number;
    costToClient: number;
    flatFee: number;
    showDFS: boolean;
    rate: number;
}

export interface Presentation {
    id: number;
    name: string;
    section: Section[];
}

export interface Section {
    id: number;
    name: string;
    include: boolean;
}

export interface RiskPreference {
    toleranceLevel: number;
}

export interface Advisor extends User {
    id?: number;
    name?: string;
    role?: Type;
}

export interface Scenario {
    id: number;
    name?: string;
    accountBalance?: number;
    accounts?: Account[];
    existingAccountsForPickList?: Map<number, ExistingAccountData[]>;
    type: Type; // Note: This can be either (0 or 1) Current or  (1N) Proposed
    goals?: GoalDetail[];
    scenarioAccountFee?: ScenarioAccountFee;
    groupCHNWStrategies?: GroupCHNWStrategy[];
    assetClasses?: AssetClasses;
    performanceAssetClasses?: Map<string, number>;
    currentAccountsAsOfDate?: string;
}

export interface AssetClasses {
    assetClassLevel1: Map<string, AssetClassLevels>;
    assetClassLevel2: Map<string, AssetClassLevels>;
    assetClassLevel3: Map<string, AssetClassLevels>;
}

export interface AssetClassLevels {
    id: number;
    name: string;
    percentage: number;
    amount: number;
    parent?: string;
}

export interface Goal {
    id: number;
    name: string;
}

export interface GoalDetail extends Goal {
    goalType?: Type;
    priority?: Type;
    startDate?: Date;
    endDate?: Date;
    endValue?: number;
    frequency?: Type;
    isValid?: boolean;
}

export interface GoalCatalogs {
    types: OrderedSet<DropdownItem>;
    priorities: OrderedSet<DropdownItem>;
    frequencies: OrderedSet<DropdownItem>;
}

export interface Client {
    id?: number;
    swpEntityId?: string | number;
    entityId?: number;
    entityType?: string;
    organizationName?: string;
    isIndividual?: boolean;
    firstName?: string;
    middleInitial?: string;
    lastName?: string;
    entitySuffix?: EntitySuffix;
    labelName?: string;
    contactId?: number;
    contactType: Type;
    isNewClient?: boolean;
    isAccountOwner?: boolean;
    externalVendor?: string;
    emailAddress?: string;
    phoneNumber?: string;
    isOptionalFieldsValid?: boolean;
}

export interface AccountOwner extends Client {
    ownerRole: Type;
    name: string;
    ownerOrder?: number;
}

export interface AccountType {
    id: number;
    description: string;
    sortOrder: number;
    taxQualified: boolean;
    qualifiedPlan: boolean;
    groupType?: GroupType;
    accountTypeId?: number;
    accountTypeDescription?: string;
    sweepModelElection?: SweepModelElection;
    investmentPrograms?: InvestmentProgram[];
    allowJointOwners?: boolean;
    typeOfOwners?: TypeOfOwner[];
    nonTaxableStatus?: boolean;
}

export interface GroupType {
    groupTypeId: number;
    groupName: string;
}

export interface Account {
    id: number;
    type: AccountType;
    custodian?: string;
    name?: string;
    isAccountNameOverMaximumLength?: boolean;
    balance?: number;
    riskCapacity?: RiskCapacity;
    owners?: AccountOwner[];
    advisors?: Advisor[];
    isSEIAccount: boolean;
    copyToProposed: boolean;
    status?: number;
    actionTag: Type;
    holdings?: Holding[];
    strategies?: Strategy[];
    portfolios?: Portfolio[];
    riskTolerance?: RiskTolerance;
    fees?: Fees;
    proposalScenarioGoalId?: number;
    goalFundingTypeId?: number;
    groupCHNWStrategyId?: number;
    combinedStrategyRisk?: CombinedStrategyRisk;
    isNewAccount?: boolean;
    isAccountTypeChanged?: boolean;
    sweepModelElection: SweepModelElection;
    currentAccountId?: number;
    currentAccountNumber?: string;
    investmentProgramId?: number;
    investmentProgramType?: string;
    typeOfOwnerId?: number;
    implementedAccountNumber?: string;
    platformFeeCapDetails?: PlatformFeeCapDetails;
    assetClasses?: AssetClasses;
    performanceAssetClasses?: Map<string, number>;
    isCurrentAccountInvestmentsModified?: boolean;
}

export interface PlatformFeeCapDetails {
    accountNumber: string;
    accountId: number;
    periodicFeeRuleId: number;
    feeCapRuleName: string;
    feeCapRuleValue: number;
}

export interface NonModelPortfolioTypeResponse {
    nonModelPortfolioTypes: NonModelPortfolioTypes[];
}

export interface NonModelPortfolioTypeRequest {
    primaryAdvisorId: number;
    accountGroupTypeId: number;
    investmentProgramId: number;
}

export interface SweepModelElection {
    platformSweepModelId: number;
    isFdic: boolean;
    currencySweepModelAllocDescription: string;
}

export interface CombinedStrategyRisk {
    strategyRiskScore: number;
    strategyRiskSnapPoint: number;
}

export interface CombinedStrategyRiskResponse {
    combinedStrategyRisk: CombinedStrategyRisk;
}

export interface Holding {
    id: number;
    ticker: string;
}

export interface Type {
    id?: number;
    description?: string;
}

export interface MdcSnapshot {
    id?: number;
    actionTagName?: string;
    isSEIAccount?: boolean;
    active?: boolean;
    changeState?: string;
    completed?: number;
    disabled?: boolean;
    optional?: boolean;
    required?: boolean;
    lastTabVisited?: number;
    goalType?: string;
    goalPriority?: string;
    goalEndDate?: string;
    goalFrequency?: string;
    goalAmount?: string;
}

export interface RiskCapacity {
    fillQuestionnaire: boolean;
    clientEmail?: string;
    clientGreeting?: string;
    score?: number;
}

export interface StrategyPayLoad {
    amount: number;
    investmentStrategyCategoryId: number;
    proposalAccountId: number;
    proposalAccountStrategyId: number;
}

export const elasticSearchAccountOwnerData = [];

export interface RiskTolerance {
    fillQuestionnaire: boolean;
    clientEmail: string;
    clientGreeting: string;
    riskMethod: number;
    selectedLowMedHighRisk: number;
    calculatedQuestionnaireRisk: number;
    questionnaire: QuestionAnswer[];
}

export interface Fees {
    advisorFeeAmount?: number;
    managementExpenseAmount?: number;
    dfsFee?: number;
    seiFundExpenseAmount?: number;
    nonSeiFundExpenseAmount?: number;
    advisorPlatformFeeAmount?: number;
    clientPlatformFeeAmount?: number;
    platformFeeBasisPoints?: number;
}

export interface NavigationTab {
    accountDetailTabs: number;
    dataTarget: string;
    dataToggle: string;
    showErrorExclamationIcon: boolean;
    description: string;
    isActive: boolean;
}


export interface Allocation {
    allocationDescription: string;
    allocationType: number;
    assetId: number;
    assetTypeId: number;
    fundTickerId: string;
    id: number;
    modelId: number;
    percentage: number;
    subModelId: number;
    swpStrategyId: number;
    assetName?: string;
    nonSei?: boolean;
    targetPctUpperTolerance: number;
    targetPctLowerTolerance: number;
    taxMgmtEligFl?: boolean;
    sorKey?: number;
    level1Id?: number;
    level1Value?: string;
    level2Id?: string;
    level2Value?: string;
    level3Id?: string;
    level3Value?: string;
    baseManagerSWPStrategyId?: number;
    cusip?: string;
    productScope?: string;
    productType?: string;
    levelClass?: number;
    selectedSubstitutions?: SubstitutionsCategory[];
    substitutionsData?: SubstitutionsDetails[];
}

export interface CustomStrategy {
    allocations: Allocation[];
    name: string;
    projectedReturn?: number;
    standardDeviation?: number;
    includeThirdPartyManager?: boolean;
    includeTaxOverlayService?: boolean;
}

export interface ProductAllocation {
    identifier: string;
    name?: string;
    allocationPct: number;
    type: string;
}

export interface PortfolioRiskAllocation {
    identifierVal: string;
    identifierType?: string;
    entityType: string;
    allocationPct: number;
}

export interface Product {
    modelId?: number;
    allocations?: ProductAllocation[];
}

export interface Strategy {
    id?: number;
    amount: number;
    cusipId?: string;
    customModelName: string;
    customModelTypeId: number;
    customStrategy?: CustomStrategy;
    customStrategyId?: number;
    fundTickerId?: string;
    /**
     * @deprecated
     */
    investmentStrategyCategoryId?: number; // Investem Program Id
    modelId: number;
    modelTypeId: number;
    nodeLevel?: string;
    proposalAccountId: number; // Account Id
    proposalAccountStrategyId?: number; // Unique Identifier
    proposalScenarioGoalId?: number;
    startingNode?: string;
    strategyName?: string;
    strategyTypeId?: number;
    swpStrategyId?: number;
    isManagedInvestmentStrategy?: boolean;
    customModelTypeName?: string;
    investmentProgramId?: number;
    investmentProgramName?: string;
    modelTypeName?: string;
    strategyTypeName?: string;
    /**
    * @deprecated
    */
    balance?: number;
    /**
    * @deprecated
    */
    minimum?: number;
    /**
    * @deprecated
    */
    taxSensitive?: boolean;
    /**
    * @deprecated
    */
    riskRank?: number;
    /**
    * @deprecated
    */
    investmentObjectiveNm?: string;
    standardDeviation?: number;
    projectedReturn?: number;
    /**
    * @deprecated
    */
    taxManagementLevel?: string;
    /**
    * @deprecated
    */
    previousUsedCounts?: number;
    /**
    * @deprecated
    */
    risk?: string;
    /**
    * @deprecated
    */
    percentageInvestmentCost?: number;
    /**
    * @deprecated
    */
    seiAdvShortDescription?: string;
    /**
    * @deprecated
    */
    name?: string;
    selectedSubstitutions?: SubstitutionsCategory[];
    firmId?: number;
    assetClasses?: AssetClasses;
    performanceAssetClasses?: Map<string, number>;
}

export interface GroupCHNWStrategy {
    accountId: number[];
    groupId: number;
    isDefault: boolean;
    modelId: number;
}

export interface ProposalAccount {
    account: Account;
    groupCHNWStrategy: GroupCHNWStrategy[];
}

export interface GoalDetailPayLoad {
    goalDetail: GoalDetail[];
    wipCheckList: WipCheckList[];
}


export interface CardDetailResponse extends CardDetail {
    data: CardDetailData;
}

export interface CardDetailData {
    attributes: CardDetail;
}

export interface CurrentAccountDetails {
    data: ProposalObjects[];
}

export interface ProposalObjects {
    proposals: Proposal[];
}

export interface AccountChecklistServiceInterface {
    submitCurrentAccountDetails(params: SaveAccountDetails): Observable<SaveAccountDetails>;
    getCurrentAccount(currentAccountId: number): Observable<SaveAccountDetails>;
    getCurrentAccountDetails(proposalId: number): Observable<CurrentAccountDetails>;
    deleteCurrentAccount(currentAccountId: number): Observable<SaveAccountDetails>;
}

export interface MockEnvironment {
    apiHost: string;
    apiUrl: string;
    enableDebugTools: boolean;
    userProfile: string;
    availableStrategies?: string;
}

export interface ProposalTaxTransitionServiceInterface {
    fetchTaxTransitionDetails(proposalId: number): Observable<EditDataObject>;
    submitTaxTransitionDetails(params: SubmitDataObject): Observable<SubmitTaxTransitionResponse>;
    saveExitTaxTransitionDetails(params: EditDataObject): Observable<EditDataObject>;
    getTaxAnalysisDetails(proposalId: number): Observable<CurrentAccountDetails>;
}

export interface ProposalTaxTransitionServiceProxy {
    fetchTaxTransitionDetails(proposalId: number): Observable<EditDataObject>;
    getGeneratedDocument(proposalId: number, proposalName: string): Observable<void>;
}

export interface ScenarioGoal {
    scenarioGoals?: GoalDetail[];
}

export interface InvestmentSelectionOptions {
    isMASProgram: boolean;
    isDFSAccount: boolean;
    isRochdaleAccount: boolean;
    isShowFavorties: boolean;
    isShowExploreSEI: boolean;
    isShowSearch: boolean;
    isViewAllStrategies: boolean;
    strategyToggleDisabled: boolean;
    selectedInvestmentProgramType: string;
    hideCustomUmaModelOptions: boolean;
}

export interface EnrichedAccountAndFeesResponse {
    accountList: Account[];
    proposalHudSummaryDTO: HudSummary;
}

export interface EnrichedPortfolioResponseItem {
    proposalAccountId: number;
    proposalAccountName: string;
    enrichedPortfolioList: Portfolio[];
}

export interface TypeOfOwner {
    typeOfOwnerId: number;
    typeOfOwnerDescription: string;
    sortOrder: number;
}

export interface ImplementProposalOptions {
    advisorGroupId?: string;
    officeLocationId?: number;
    proposalId?: number;
    selectedProposalAccounts?: number[];
}

export interface ImplementedAccountResponse {
    proposalAccountStatuses: ImplementedAccountStatus[];
    validationMessages?: ValidationMessage[];
}

export interface ImplementedAccountStatus {
    proposalAccountId: number;
    accountOpenId?: string;
}

export interface ValidationMessage {
    proposalAccountIds: number[];
    validationMessage: string;
}

export interface MinDataProposal {
    proposalId: number;
    proposalName: string;
    proposalLevelAdvisors: Advisor[];
    proposalAccounts: Account[];
    existingAccounts: Account[];
}

export interface ProposalStatusResponse {
    eligibleForReactivation?: boolean;
    proposalAccountStatuses?: ProposalPendingAccountStatus[];
}

export interface ProposalPendingAccountStatus {
    accountNumber: string;
    proposalAccountId: number;
    proposalId: number;
    status: string;
    statusId: number;
    statusDate: Date;
}

export interface ProposalReactivateResponse {
    success: boolean;
    proposalAccounts: ProposalPendingAccountStatus[];
    reactivationErrors: string[];
}
