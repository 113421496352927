/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ContentTypes, DocumentCreation } from '../model/enums';
import {
    CoverPageImageTheme,
    PdfStatement, PresentationSection, PresentationSettings, ProposalSection, ProposalStatement, RetrieveFileRequest, SelectOption
} from '../model/presentation';
import { DisplayPreference } from '../model/presentation-settings';
import { Advisor, Client, Proposal, Scenario, Type } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { ProposalService } from './proposal.service';
import { GenericErrorService } from './system/generic-error.service';
import { GlobalService } from './system/global.service';
import { UserProfileService } from './user-profile.service';

@Injectable({
    providedIn: 'root'
})
export class PresentationSettingService {
    constructor(
        private http: HttpClient,
        protected carGlobal: GlobalService,
        private proposalService: ProposalService,
        private genericErrorService: GenericErrorService,
        private userProfileService: UserProfileService,
        private featureFlagService: FeatureFlagService
    ) { }
    public _hypotheticalReturnBYDropDown = new Subject<boolean>();

    public getPresentationSettingById(
        proposalId: number,
        advisorId: number
    ): Observable<PresentationSettings> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(
                'retrievePresentationSetting'
            ),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'advisorId',
                    value: advisorId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.length > 0 &&
                    response.data[0].presentationSettings &&
                    response.data[0].presentationSettings.length > 0
                ) {
                    return response.data[0].presentationSettings[0];
                }
            })
        );
    }

    public initializePresentationSetting(proposalId: number, isCurrentAccountsPresentInProposal: boolean) {
        return this.proposalService.currentProposal
            .pipe(
                map((proposal: Proposal) => {
                    const presentationSetting: PresentationSettings = {};
                    presentationSetting.id = 0;
                    presentationSetting.proposalId = proposalId;
                    presentationSetting.proposalName = proposal.name;
                    presentationSetting.scenarioName = proposal.scenarios[0].name;
                    presentationSetting.advisorDisplayName = this.getAdvisors(proposal.scenarios);
                    presentationSetting.clientDisplayName = this.getClients(proposal.clients);
                    presentationSetting.firmDisplayName = this.userProfileService.firm?.firmName;
                    presentationSetting.documentCreationOption = DocumentCreation.SeparateDocuments;  // Note: Default to 2
                    presentationSetting.sections = [];
                    presentationSetting.isIncludeExistingAccountsComparisonInPresentation =
                        this.featureFlagService.checkExistingAccountsSectionEnabled() && isCurrentAccountsPresentInProposal;

                    let statementType: Type = {
                        id: 1,
                        description: 'Proposal'
                    };
                    let section: PresentationSection =
                        this.createPresentationSettingSection(proposal, 'Proposal', 'Include all accounts in proposal', statementType);
                    section.id = 1;
                    presentationSetting.sections.push(section);

                    statementType = {
                        id: 2,
                        description: 'IPS'
                    };
                    section =
                        this.createPresentationSettingSection(proposal, 'IPS Report', 'Include all accounts in IPS', statementType);
                    section.id = 2;
                    presentationSetting.sections.push(section);

                    return presentationSetting;
                })
            );
    }

    private createPresentationSettingSection(
        proposal: Proposal,
        sectionLabel: string,
        subSectionLabel: string,
        statementType: Type
    ) {
        const {
            section,
            subSection
        }: {
            section: PresentationSection;
            subSection: PresentationSection;
        } = this.createPresentationSection(
            sectionLabel,
            subSectionLabel,
            statementType
        );

        section.sections.push(subSection);

        const scenarioSection: PresentationSection = null;
        const accountSection: PresentationSection = null;

        this.createProposedAccounts(
            proposal,
            scenarioSection,
            subSection,
            accountSection,
            4,
            statementType
        );

        return section;
    }

    private createPresentationSection(
        sectionLabel,
        subSectionLabel,
        statementType: Type
    ) {
        const section: PresentationSection = {
            id: 1,
            label: sectionLabel,
            include: true,
            sortOrder: 1,
            level: 1,
            isToggle: true,
            sections: [],
            statementType
        };
        const subSection: PresentationSection = {
            id: 2,
            label: subSectionLabel,
            include: true,
            sortOrder: 2,
            level: 2,
            isToggle: true,
            sections: [],
            statementType
        };
        return { section, subSection };
    }

    private createProposedAccounts(
        proposal: Proposal,
        scenarioSection: PresentationSection,
        subSection: PresentationSection,
        accountSection: PresentationSection,
        identifier: number,
        statementType: Type
    ) {
        proposal.scenarios.forEach((scenario) => {
            scenarioSection = {
                id: identifier,
                label: scenario.name,
                include: true,
                sortOrder: 1,
                level: 3,
                isToggle: false,
                proposalScenarioId: scenario.id,
                statementType,
                sections: []
            };

            subSection.sections.push(scenarioSection);

            let nextId = scenario.accounts.length + 1;

            scenario.accounts.forEach((account) => {
                // Note: per business rule this might change
                let labelValue: string = account.name;
                if (account.isSEIAccount) {
                    // Note: use accounttype description
                    // account.type.accountTypeDescription
                    labelValue = account.type.description;
                }
                accountSection = {
                    id: nextId,
                    label: labelValue,
                    include: true,
                    sortOrder: nextId,
                    level: 4,
                    isToggle: true,
                    proposalAccountId: account.id,
                    statementType,
                    sections: []
                };
                scenarioSection.sections.push(accountSection);
                nextId++;
            });
        });
        return { scenarioSection, accountSection };
    }

    public getAdvisors(scenarios: Scenario[]): string {
        const advisors: Advisor[] = [];
        if (scenarios) {
            scenarios.forEach((scenario) => {
                scenario.accounts.forEach((account) => {
                    if (account.advisors) {
                        account.advisors.forEach((advisor) => {
                            if (
                                !advisors.find(
                                    (a) => a.entityId === advisor.entityId
                                )
                            ) {
                                advisors.push(advisor);
                            }
                        });
                    }
                });
            });
        }
        advisors.sort((a, b) => a.role.id - b.role.id);
        const advisorDisplayName = advisors.map((advisor) => advisor.name);

        return advisorDisplayName.join(', ');
    }

    public getClients(clients: Client[]): string {
        let value: string = '';
        clients.forEach((client) => {
            if (client) {
                let labelText = '';
                if (client.firstName) {
                    labelText = `${labelText} ${client.firstName}`;
                }

                if (client.middleInitial && client.middleInitial != null) {
                    labelText = `${labelText} ${client.middleInitial}`;
                }

                if (client.lastName) {
                    labelText = `${labelText} ${client.lastName}`;
                }

                if (client.entitySuffix && client.entitySuffix.suffixName) {
                    labelText = `${labelText} ${client.entitySuffix.suffixName}`;
                }

                if (client.organizationName) {
                    labelText = ` ${client.organizationName}`;
                }
                value = value + labelText + ',';
            }
        });
        value = value.trim();
        return value.slice(0, -1);
    }

    public savePresentationSetting(presentationSetting: PresentationSettings) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(
                'upsertPresentationSetting'
            )
        );

        const json = {
            data: [
                {
                    presentationSettings: [presentationSetting]
                }
            ]
        };

        if (presentationSetting.id === 0) {
            return this.http.post<SeiPayload>(apiUrl, json).pipe(
                map((response) => {
                    if (
                        response &&
                        response.error &&
                        response.error.length > 0
                    ) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                })
            );
        } else {
            return this.http.put<SeiPayload>(apiUrl, json).pipe(
                map((response) => {
                    if (
                        response &&
                        response.error &&
                        response.error.length > 0
                    ) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                })
            );
        }
    }

    public createPdfStatement(
        presentationSetting: PresentationSettings
    ): Observable<PdfStatement> {
        return this.retrieveStaticSections().pipe(
            map((pdfStatements: PdfStatement) => {
                const pdfStatement: PdfStatement = {
                    id: presentationSetting.id,
                    proposalId: presentationSetting.proposalId,
                    proposalName: `Presentation for ${presentationSetting.clientDisplayName}`,
                    proposalDisplayProperty: {
                        clientName: presentationSetting.clientDisplayName,
                        firmName: pdfStatements.proposalDisplayProperty.firmName,
                        // Note: Replace with userProfile firm name
                        advisorName: presentationSetting.advisorDisplayName,
                        advisorEmail:
                            pdfStatements.proposalDisplayProperty.advisorEmail,
                        // Note: Replace with userProfile advisor email
                        advisorPhone:
                            pdfStatements.proposalDisplayProperty.advisorPhone
                        // Note: Replace with userProfile advisor phone number
                    },
                    proposalStatement: []
                };

                pdfStatement.proposalStatement.push(
                    this.generateStatement(
                        presentationSetting,
                        pdfStatements.proposalStatement[0],
                        'Proposal'
                    )
                );
                pdfStatement.proposalStatement.push(
                    this.generateStatement(
                        presentationSetting,
                        pdfStatements.proposalStatement[1],
                        'IPS Report'
                    )
                );

                return pdfStatement;
            })
        );
    }

    private generateStatement(
        presentationSetting: PresentationSettings,
        staticStatement: ProposalStatement,
        sectionParentName: string,
        sectionLevel: number = 1
    ): ProposalStatement {
        let statement: ProposalStatement = null;

        const presenationSettingSection = presentationSetting.sections.find(
            (section) =>
                section.level === sectionLevel &&
                section.include &&
                section.label === sectionParentName
        );

        if (presenationSettingSection) {
            // Note: If Presentation Settings Document Creation Type = Single (1st Option) -- "Proposal for <Proposal Name>"
            let label: string = presenationSettingSection.label;
            // Note: taking out for now causing errors since there is no scenarioName
            if (
                presentationSetting.documentCreationOption === null || presentationSetting.documentCreationOption ===
                DocumentCreation.SeparateDocuments
            ) {
                label = `${label} for ${presentationSetting.proposalName}`;
            } else {
                label = `${label} for ${presentationSetting.scenarioName}`;
            }

            statement = {
                id: 0,
                toggle: true,
                name: label,
                sections: staticStatement.sections,
                statementType: presenationSettingSection.statementType
            };
        }
        return statement;
    }

    public saveDefaultSelections(pdfStatementSave: PdfStatement): Observable<PdfStatement> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('primaryAdvisorPreference'),
            [
                {
                    key: 'proposalId',
                    value: pdfStatementSave.proposalId
                }
            ]
        );

        const json = {
            data: [
                {
                    checkList: [pdfStatementSave]
                }
            ]
        };
        return this.http.post<SeiPayload>(apiUrl, json).pipe(
            map((response) => {
                if (response && response.error && response.error.length > 0) {
                    this.genericErrorService.setGenericError({
                        code: response.error.code,
                        description: response.error.message
                    });
                }
                return response.data[0]['checkList'][0];
            })
        );
    }

    public savePdfStatement(pdfStatementSave: PdfStatement):
        Observable<{ presentationChecklist: SeiPayload }> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('presentationChecklist'),
            [
                {
                    key: 'proposalId',
                    value: pdfStatementSave.proposalId
                }
            ]
        );

        const json = {
            data: [
                {
                    checkList: [pdfStatementSave]
                }
            ]
        };

        const stream = forkJoin(
            {
                presentationChecklist: this.http.put<SeiPayload>(apiUrl, json)
            }
        );

        return stream;
    }

    public getCheckListById(proposalId: number): Observable<PdfStatement> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('presentationChecklist'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (
                    response &&
                    response.data &&
                    response.data.length > 0 &&
                    response.data[0].checkList &&
                    response.data[0].checkList.length > 0
                ) {
                    return response.data[0].checkList[0];
                }
            })
        );
    }

    public mapSettingSectionToProposalSections(
        sections: PresentationSection[]
    ): ProposalSection[] {
        const proposalSection: ProposalSection[] = [];

        sections.forEach((children) => {
            let section: ProposalSection;
            if (children.level === 1) {
                section = this.createProposalSection(children);
            }
            children.sections.forEach((element2) => {
                element2.sections.forEach((element3) => {
                    let section3: ProposalSection;
                    if (element3.level === 3) {
                        section3 = this.createProposalSection(element3);
                        section.sections.push(section3);
                    }
                    element3.sections.forEach((element4) => {
                        let section4: ProposalSection;
                        if (element2.include || element4.include) {
                            section4 = this.createProposalSection(element4);
                            section3.sections.push(section4);
                        }
                    });
                });
            });

            proposalSection.push(section);
        });

        return proposalSection;
    }

    private createProposalSection(
        presentationSection: PresentationSection
    ): ProposalSection {
        return {
            id: presentationSection.id,
            statementSectionId: presentationSection.id,
            name: presentationSection.label,
            include: presentationSection.include,
            pinned: false,
            toggle:
                presentationSection.level === 1 ||
                presentationSection.level === 2,
            hidden: presentationSection.include,
            isCollapsed: false,
            sectionContent: {},
            sortOrder: presentationSection.sortOrder,
            inputProperty: {},
            pageProperty: {},
            level: presentationSection.level,
            sections: [],
            isActive: false
        };
    }

    public retrieveStaticSections(): Observable<PdfStatement> {
        // Note: This is static sections...will be injected into checklist stack
        return this.http
            .get<HttpResponse<SeiPayload>>('/presentationSections')
            .pipe(
                share(),
                map((response) => {
                    if (response) {
                        return response.body.data[0].checkList[0];
                    }
                })
            );
    }

    public getProposalFirmLogo(proposalId: number): Observable<SelectOption[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getProposalFirmLogo'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (response && response.data && response.data.length > 0) {
                    if (response.error && response.error.length > 0) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                    const selectOption: SelectOption[] = [];
                    response.data[0].documents.forEach((doc) => {
                        selectOption.push({
                            value: doc.documentPath,
                            label: doc.displayName.substring(0, doc.displayName.lastIndexOf('.'))
                        });
                    });
                    return selectOption;
                }
            })
        );
    }

    public getImageName(imagePath: string): string {
        const arrayText = imagePath.split('-');
        let index = 2;
        let imageNameExt: string = '';
        // fix for the brand logo image name with '-'
        while (++index < arrayText.length) {
            if (index < 4) {
                imageNameExt = arrayText[index];
            } else {
                imageNameExt = imageNameExt.concat('-').concat(arrayText[index]);
            }
        }
        return imageNameExt.substring(0, imageNameExt.lastIndexOf('.'));
    }

    public getCoverImages(proposalId: number): Observable<CoverPageImageTheme[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getCoverPageImages'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (response && response.data && response.data.length > 0) {
                    if (response.error && response.error.length > 0) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                    return response.data;
                }
            })
        );

    }

    public getFirmLogoDisplayName(proposalId: number): Observable<string> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getFirmLogoDisplayName'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (response && response.data && response.data.length > 0) {
                    if (response.error && response.error.length > 0) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                    let firmLogoDisplayName: string;
                    response.data.forEach((firmLogo) => {
                        firmLogoDisplayName = firmLogo;
                    });

                    return firmLogoDisplayName;
                }
            })
        );
    }

    public generateDocumentsPresentationChecklist(proposalId: number, proposalGenerationDate: string): Observable<string[]> {
        const apiUrl: string = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('generateDocumentsPresentationChecklist'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'firmGroupId',
                    value: this.userProfileService.firm ? this.userProfileService.firm.firmGroupId : null
                }
            ]
        );
        const json = {
            presentationGenerationDate: proposalGenerationDate
        };
        return this.http.post(apiUrl, json).pipe(
            map((response: SeiPayload) => {
                if (response) {
                    return response.data;
                }
            })
        );
    }

    public getPresentationCheckListStatus(proposalId: number): Observable<string> {
        const apiUrl: string = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getProposalStatus'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.get(apiUrl, {
            responseType: 'text' as 'json'
        }).pipe(
            map((response: string) => {
                if (response) {
                    return response;
                }
            })
        );
    }

    public getPresentationCheckListDocument(proposalId: number, data: string[]): Observable<ArrayBuffer> {

        const isZipFile = this.validateDownloadDocuments(data);

        const pdfFileName = data[0] || '';

        if (isZipFile) {
            return this.getPresentationCheckListDownloadZipDocument(proposalId, data);
        } else {
            return this.getPresentationCheckListPdfDocument(proposalId, pdfFileName);
        }
    }

    private getPresentationCheckListDownloadZipDocument(proposalId: number, data: string[]): Observable<ArrayBuffer> {
        try {

            const fileName = data.find((documentResponse: string) => {
                return documentResponse.toLowerCase().endsWith('.zip');
            });

            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('downloadPresentation'),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    },
                    {
                        key: 'fileName',
                        value: fileName
                    }
                ]
            );

            return this.http.get<ArrayBuffer>(apiUrl, {
                headers: new HttpHeaders({ 'Content-Type': ContentTypes.Zip }),
                responseType: 'blob' as 'json'
            }).pipe(
                map((objectResponse: ArrayBuffer) => {
                    const blob = new Blob([objectResponse], {
                        type: ContentTypes.Zip
                    });

                    const fileUrl = window.URL.createObjectURL(blob);

                    this.getDownloadHtmlElement(fileName, fileUrl).click();
                    return objectResponse;
                })
            );
        } catch (error) {
            return throwError(error);
        }
    }

    private getPresentationCheckListPdfDocument(proposalId: number, fileName: string): Observable<ArrayBuffer> {

        try {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('previewPresentationPdf'),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    },
                    {
                        key: 'fileName',
                        value: fileName
                    }
                ]
            );

            return this.http.get<ArrayBuffer>(apiUrl, {
                headers: new HttpHeaders({ 'Content-Type': ContentTypes.Pdf }),
                responseType: 'blob' as 'json'
            }).pipe(
                map((objectResponse: ArrayBuffer) => {
                    const blob = new Blob([objectResponse], {
                        type: ContentTypes.Pdf
                    });

                    const fileUrl = window.URL.createObjectURL(blob);
                    window.open(fileUrl, '_blank');

                    return objectResponse;
                })
            );
        } catch (error) {
            return throwError(error);
        }
    }

    public validateDownloadDocuments(documents: string[]): boolean {
        if (documents.length > 0) {
            const totalDocuments: number = documents.filter((documentResponse: string) => {
                return documentResponse.toLowerCase().endsWith('.pdf') || documentResponse.toLowerCase().endsWith('.xlsx');
            }).length;
            if (totalDocuments > 1) {
                return documents.filter((documentResponse: string) => {
                    return documentResponse.toLowerCase().endsWith('.zip');
                }).length > 0;
            }
        }
        return false;
    }

    public getDownloadHtmlElement(fileName: string, linkRefence: string): HTMLAnchorElement {
        const link = document.createElement('a');
        link.href = linkRefence;
        link.target = '_blank';
        link.download = fileName;
        return link;
    }

    public openDownloadedDocument(proposalId: number, fileName: string) {
        try {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('downloadProposalPresentation'),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    }
                ]
            );
            return this.http.get<Blob>(apiUrl, {
                headers: new HttpHeaders({ 'Content-Type': ContentTypes.Zip }),
                responseType: 'blob' as 'json'
            }).pipe(
                map((objectResponse: Blob) => {
                    const fileUrl = window.URL.createObjectURL(objectResponse);
                    this.getDownloadHtmlElement(fileName, fileUrl).click();
                })
            );
        } catch (error) {
            return throwError(error);
        }
    }

    public getGeneratedDocument(proposalId: number, proposalName: string): Observable<void> {
        // const name: string[] = [`${proposalId}-${proposalName}`];
        const proposalNameFilter = proposalName.replace(/[^a-zA-Z0-9]+/ig, '');
        let fileName = `${proposalId}_${proposalNameFilter}`;
        fileName = `${fileName}`;

        return this.openDownloadedDocument(proposalId, fileName);
    }

    public getSectionFromGroup(sectionGroup: ProposalSection, sectionName: string): ProposalSection {
        if (sectionGroup) {
            const section =
                sectionGroup.sections.find((sectionItem: ProposalSection) =>
                    sectionItem.name === sectionName
                );
            return section;
        }
        return undefined;
    }

    public getGroupProposalStatement(
        proposalStatement: ProposalStatement,
        proposalSectionName: string
    ): ProposalSection {
        if (proposalStatement && Array.isArray(proposalStatement.sections)) {
            const sectionGroup =
                proposalStatement.sections.find((sectionGroupItem: ProposalSection) =>
                    sectionGroupItem.name === proposalSectionName
                );
            return sectionGroup;
        }
        return undefined;
    }

    public getGroupPdfStatement(
        pdfStatement: PdfStatement,
        proposalType: number,
        proposalSectionName: string): ProposalSection {

        const proposalStatement: ProposalStatement =
            pdfStatement.proposalStatement
                .find((proposalItem: ProposalStatement) =>
                    proposalItem.statementType.id === proposalType);

        return this.getGroupProposalStatement(proposalStatement, proposalSectionName);
    }

    public setValuesToSection(section: ProposalSection, hidden: boolean, name?: string): void {
        if (section) {
            section.hidden = hidden;
            section.name = name || section.name;
        }
    }

    public compareSections(previousSection: ProposalSection, changedSection: ProposalSection): boolean {
        const sectionHidden: boolean = previousSection?.hidden || changedSection?.hidden;
        if (sectionHidden) {
            return true;
        }
        const bothSectionsIncluded: boolean = previousSection?.include === changedSection?.include;
        const inputPropertiesEqual: boolean = previousSection?.inputProperty?.value === changedSection?.inputProperty?.value;
        let sectionsEqual: boolean = bothSectionsIncluded && inputPropertiesEqual;
        if (sectionsEqual) {
            // Check all subsections are equal
            sectionsEqual = previousSection.sections.every((previousSectionItem: ProposalSection) => {
                const changedSectionItem: ProposalSection =
                    changedSection.sections.find((sectionItem: ProposalSection) => sectionItem.name === previousSectionItem.name);
                return this.compareSections(previousSectionItem, changedSectionItem);
            });
        }
        return sectionsEqual;
    }


    public getPresentationCheckListPreviewImage(proposalId: number, fileName: string): Observable<Blob> {

        try {

            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('previewPresentationPdf'),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    },
                    {
                        key: 'fileName',
                        value: fileName
                    }
                ]
            );

            return this.http.get<Blob>(apiUrl, {
                headers: new HttpHeaders({ 'Content-Type': 'image/jpg' }),
                responseType: 'blob' as 'json'
            }).pipe(
                share()
            );
        } catch (error) {
            return throwError(error);
        }
    }

    public saveProposalSectionSortAsync(proposalId: number, proposalSection: ProposalSection) {
        const apiUrl: string = this.carGlobal.configService.routeFormatter(this.carGlobal.configService.getEndPoint('asyncUpdateSortOrder'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
        return this.http.post(apiUrl, proposalSection);
    }

    public get hypotheticalReturnBYDropDownValues(): Observable<boolean> {
        return this._hypotheticalReturnBYDropDown;
    }

    public retrieveFile(request: RetrieveFileRequest): Observable<Blob> {
        if (!request?.filePath) {
            return of();
        }
        const apiUrl: string = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveFile')
        );

        return this.http.post(apiUrl, request, { responseType: 'blob' });

    }

    public saveAdvisorDisplayPreferences(displayPreferenceRequest: DisplayPreference): Observable<DisplayPreference> {
        const apiUrl: string = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('saveAdvisorDisplayPreferences')
        );
        return this.http.post<DisplayPreference>(apiUrl, displayPreferenceRequest);
    }
}
