<div class="wip-summary tax-padding">
    <sei-car-wip-checklist-spacer *ngIf="showExistingAccountsSection()"></sei-car-wip-checklist-spacer>
    <car-wip-checklist-account-types class="border-bottom"
                                     [wipCheckList]="seiAccountsWipCheckList"
                                     [existingAccountsWipCheckList]="existingAccountsWipCheckList"
                                     [sectionType]="sectionType"
                                     [proposalId]="proposalId"
                                     [checkListId]="checkListId"
                                     [accountTypeLabel]="proposedAccountsLabel">
    </car-wip-checklist-account-types>
    <sei-car-wip-checklist-spacer></sei-car-wip-checklist-spacer>
    <car-wip-checklist-fees [wipCheckList]="feesWipCheckList"
                            [proposal]="proposal"></car-wip-checklist-fees>
    <sei-car-wip-checklist-spacer></sei-car-wip-checklist-spacer>
    <div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-header wip-checklist-section-header-bg-gray"
         [class.wip-checklist-section-header-collapsed]="!isTaxTransitionAnalysisSectionVisible">
        <div class="wip-summary-left subhead">
            <h4>Tax Transition Analysis</h4>
        </div>
        <div class="wip-summary-right">
            <a class="section-arrow" aria-label="Expand Tax Transition Analysis Section" (click)="toggleTaxTransistionAnalysisSection()">
                <i class="fas" [class.fa-chevron-right]="!isTaxTransitionAnalysisSectionVisible" [class.fa-chevron-down]="isTaxTransitionAnalysisSectionVisible"></i>
            </a>
        </div>
    </div>
    <div *ngIf="isTaxTransitionAnalysisSectionVisible">
        <sei-car-account-checklist [isCurrentAccountAddEnable]="isCurrentAccountAddEnable"
                                   [proposal]="proposal"
                                   [isReadUser]="isReadUser || isChecklistLocked()"
                                   [showLockTooltip]="isChecklistLocked()"
                                   (enableTaxTransitionAddEvent)="enableTaxTransitionAdd($event)">
        </sei-car-account-checklist>
        <div class="wip-summary tax-padding">
            <div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-subheader wip-checklist-section-header-bg-white"
                 [class.wip-checklist-section-header-collapsed]="!showErrorBanner && !showInProcess && !showSaved && !reportGenerated">
                <div class="wip-summary-left subhead">
                    <h4>Tax Transition Details</h4>
                </div>
                <div class="wip-summary-right">
                    <div class="wip-summary-icon"
                         [class.disabled]="!isTaxTransitionEnable"
                         (click)="loadTaxTransitionAnalysis()">{{addEditButton}}</div>
                </div>
            </div>
            <div *ngIf="showErrorBanner"
                 class="wip-section-border"
                 [class.wip-section-border-rounded]="!showInProcess">
                <sei-alert [showCloseIcon]="false"
                           [iconOverride]="false"
                           [type]="typeError">
                    <div [innerHTML]="contentError"></div>
                </sei-alert>
            </div>
            <div *ngIf="showInProcess"
                 class="wip-section-border wip-section-border-rounded">
                <sei-alert [showCloseIcon]="false"
                           [iconOverride]="false"
                           [type]="typeInProcess">
                    <div [innerHTML]="contentInProcess"></div>
                </sei-alert>
            </div>
        </div>
        <div class="wip-summary"
             *ngIf="showSaved">
            <div class="wip-summary-row head success-message wip-section-border wip-checklist-section-header-bg-gray"
                 [class.wip-section-border-rounded]="!showTaxRateSaveFailedInfo">
                <div class="wip-summary-left success-icon tta-info-row">
                    <car-wip-checklist-status-indicator [mdcSnapShot]="mdcSnapShotIncomplete"></car-wip-checklist-status-indicator>
                    The Tax Transition Analysis is not yet completed. Select edit to continue.
                </div>
            </div>

            <div *ngIf="showTaxRateSaveFailedInfo"
                 class="wip-summary-row head wip-checklist-section-header-bg-gray wip-section-border wip-section-border-rounded">
                <div class="tta-info-row">
                    <span>
                        <i class="fas fa-info-circle fas-info tta-info-icon-blue"></i>
                        The tax rates were not able to be saved at this time.
                    </span>
                </div>
            </div>
        </div>
        <div class="wip-summary"
             *ngIf="reportGenerated">
            <div class="wip-summary-row head success-message wip-section-border wip-section-background-green wip-section-padding-bottom"
                 [class.wip-section-border-rounded]="!showTaxRateSaveFailedInfo">
                <div class="wip-summary-left success-icon">
                    <car-wip-checklist-status-indicator [mdcSnapShot]="mdcSnapShot"></car-wip-checklist-status-indicator>
                </div>
                <div class="wip-summary-left transition-success tta-info-row">
                    Your Tax Transition Analysis is ready. Please click download to access your report(s).
                </div>
                <div class="wip-summary-right success-download">
                    <div class="menu-caret">
                        <a href="javascript:void(0)"><i class="fas fa-download" aria-hidden="true"
                                (click)="downloadTransitionFile()"></i></a>
                    </div>
                </div>
            </div>
            <div *ngIf="showTaxRateSaveFailedInfo"
                 class="wip-summary-row head wip-checklist-section-header-bg-gray wip-section-border wip-section-border-rounded">
                <div class="tta-info-row">
                    <span>
                        <i class="fas fa-info-circle fas-info tta-info-icon-blue"></i>
                        The tax rates were not able to be saved at this time.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <sei-car-wip-checklist-spacer></sei-car-wip-checklist-spacer>
    <sei-car-wip-checklist-goals [wipCheckListGoals]="wipCheckListGoals"
                                 [sectionType]="wipCheckListGoals.name"
                                 [proposal]="proposal">
    </sei-car-wip-checklist-goals>
</div>
<div *ngIf="isLoadTaxTransitionAnalysis">
    <sei-car-proposal-tax-transition-analysis [proposal]="proposal"
                                              [proposalId]="proposalId"
                                              [editObject]="editObject"
                                              [accountOwners]="accountOwners"
                                              [mainTitle]="mainTitle"
                                              [subtitle]="subtitle"
                                              [isReadUser]="isReadUser || isChecklistLocked()"
                                              [showLockTooltip]="isChecklistLocked()"
                                              [isDataSaved]="showSaved"
                                              (displayModalEvent)="showTaxTransitionModal($event)"></sei-car-proposal-tax-transition-analysis>
</div>
