/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountType } from '@CarInterfaces/account';
import { ExistingAccountData, ExistingAccountPortfolioPurposeMappingTable } from '@CarModels/existing-accounts';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { CommandText, DelegationService, GenericErrorService, NonModelPortfolioTypeResponse, OwnerRoleDescription, PortfolioPurpose, Proposal, ProposalService } from '@sei/advisor-client-review-proposal-ux';
import { DropdownItem, ModalWindowComponent } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import _ from 'lodash';
import { forkJoin, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-existing-accounts-portfolio-mapping',
    templateUrl: './existing-accounts-portfolio-mapping.component.html',
    styleUrls: ['./existing-accounts-portfolio-mapping.component.scss']
})
export class ExistingAccountsPortfolioMappingComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    private isModalForForceRefresh: boolean = false;
    @Input()
    public selectedExistingAccounts: ExistingAccountData[] = [];
    @Input()
    set showModal(value: boolean) {
        this.isShowModal = value;
        if (this.isShowModal && this.isRequestCompleted) {
            this.buildPortfolioMappingTable();
        }
    }
    @ViewChild('portfolioMappingIssueModal', { static: false })
    public portfolioMappingIssueModal: ModalWindowComponent;

    @Output()
    public cancelClicked: EventEmitter<boolean> = new EventEmitter();

    public mapPortfolioPurpose: ExistingAccountPortfolioPurposeMappingTable[] = [];
    public nonModelPortfolioTypeMap: Map<number, OrderedSet<DropdownItem>> = new Map();
    public accountTypes: AccountType[] = [];
    public disableContinueButton: boolean = true;

    private proposal: Proposal;
    private proposalId: number;
    private scenarioId: number;
    private primaryDsrId: number;
    private isRequestCompleted: boolean = false;
    private accountIdSet: number[] = [];
    private existingAccountDataMap: Map<number, ExistingAccountData> = new Map();

    private nonModelPortfolioPurposeResponseMap: Map<number, NonModelPortfolioTypeResponse> = new Map();
    private isShowModal: boolean = false;

    public constructor(
        private readonly spinnerService: SpinnerService,
        private readonly genericErrorService: GenericErrorService,
        private readonly accountTypesService: AccountTypesService,
        private router: Router,
        private proposalService: ProposalService,
        private existingAccountsService: ExistingAccountsService,
        private delegationService: DelegationService
    ) {
        super('ExistingAccountsPortfolioMappingComponent');
    }

    public ngOnInit(): void {
        this.spinnerService.start();
        const proposalSubscription: Subscription = this.proposalService.currentProposal.subscribe({
            next: (proposal: Proposal) => {
                if (proposal) {
                    this.proposal = proposal;
                    this.proposalId = proposal.id;
                    this.scenarioId = proposal.scenarios[0].id;
                    this.primaryDsrId = proposal.advisors.find(
                        (advisor) => advisor.role.description === OwnerRoleDescription.Primary
                    ).entityId;
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(proposalSubscription);

        const accountTypeSubscription: Subscription =
            this.accountTypesService.getAccountTypes(this.primaryDsrId).subscribe({
                next: (response: AccountType[]) => {
                    this.accountTypes = response;
                },
                error: (error) => {
                    this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                }
            });
        this.subscriptions.push(accountTypeSubscription);

        const nonModelPortfolioRequests: Array<Observable<NonModelPortfolioTypeResponse>> = [];
        if (this.selectedExistingAccounts.length) {
            this.selectedExistingAccounts.forEach((selectedAccount) => {
                nonModelPortfolioRequests.push(this.existingAccountsService.getPortfolioPurposes({
                    primaryAdvisorId: selectedAccount.currentAccountPrimaryAdvisorInfo ?
                        selectedAccount.currentAccountPrimaryAdvisorInfo.primaryAdvisorId : this.primaryDsrId,
                    accountGroupTypeId: this.getAccountGroupTypeIdFromAccountTypeName(selectedAccount.accountType),
                    investmentProgramId: selectedAccount.investmentProgramId
                }));
                this.accountIdSet.push(selectedAccount.accountId);
                this.existingAccountDataMap.set(selectedAccount.accountId, selectedAccount);
            });
        } else {
            this.navigateAwayFromModal(this.isModalForForceRefresh);
        }
        this.subscriptions.push(
            forkJoin(nonModelPortfolioRequests)
                .subscribe({
                    next: (response: NonModelPortfolioTypeResponse[]) => {
                        if (response) {
                            response.forEach((nonModelPortfolioResponse: NonModelPortfolioTypeResponse, index: number) => {
                                this.nonModelPortfolioTypeMap.set(
                                    this.accountIdSet[index],
                                    this.createDropdownItemsForPortfolioTypes(nonModelPortfolioResponse, this.accountIdSet[index])
                                );
                                this.nonModelPortfolioPurposeResponseMap.set(this.accountIdSet[index], nonModelPortfolioResponse);
                            });
                            this.isRequestCompleted = true;
                            this.buildPortfolioMappingTable();
                            this.spinnerService.stop();
                        }
                    },
                    error: (error) => {
                        this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                        this.spinnerService.stop();
                    },
                    complete: () => {
                        this.spinnerService.stop();
                    }
                })
        );
    }

    public buildPortfolioMappingTable(): void {
        if (this.isRequestCompleted && this.nonModelPortfolioTypeMap.size === this.accountIdSet.length) {
            this.selectedExistingAccounts.forEach((account) => {
                const accountName: string = this.getAccountName(account);
                const portfoliosThatHaveMarketValue =
                    this.existingAccountsService.filterOutPortfoliosWithNoMarketValue(account.existingNonModelPortfolios);
                portfoliosThatHaveMarketValue.forEach((portfolio) => {
                    if (_.isNil(portfolio.portfolioPurpose.portfolioPurposeId) || portfolio.portfolioPurpose.portfolioPurposeId > 4) {
                        this.mapPortfolioPurpose.push({
                            portfolioId: portfolio.existingAccountPortfolioId,
                            accountName,
                            accountId: account.accountId,
                            accountNumber: account.accountNumber,
                            portfolioName: portfolio.portfolioName,
                            portfolioTypes: _.cloneDeep(this.nonModelPortfolioTypeMap.get(account.accountId)),
                            selectedPortfolioPurpose: undefined,
                            selectedPortfolioType: undefined,
                            isDeleted: false,
                            shouldDisableDeleteOption: false
                        });
                    }
                });
            });
            if (this.mapPortfolioPurpose.length > 0 && this.isShowModal) {
                this.portfolioMappingIssueModal.showModalBox();
            } else if (this.isShowModal) {
                this.navigateAwayFromModal(this.isModalForForceRefresh);
            }
        } else {
            this.navigateAwayFromModal(this.isModalForForceRefresh);
        }
    }

    private getAccountName(account: ExistingAccountData) {
        const positionOfLastSpace: number = account.accountName.lastIndexOf(' ');
        const accountName: string = account.accountName.slice(0, positionOfLastSpace);
        return accountName;
    }

    public onModalContinue(): void {
        this.portfolioMappingIssueModal.hideModalBox();
        this.updateProposalObject();
        this.proposalService.changedProposal(this.proposal);
        this.navigateAwayFromModal(this.isModalForForceRefresh);
    }

    public updateProposalObject(): void {
        const accountAndModifiedPortfolioMap: Map<number, Map<number, ExistingAccountPortfolioPurposeMappingTable>> = new Map();
        this.mapPortfolioPurpose.forEach((portfolio) => {
            if (accountAndModifiedPortfolioMap.has(portfolio.accountId)) {
                accountAndModifiedPortfolioMap.get(portfolio.accountId).set(portfolio.portfolioId, portfolio);
            } else {
                const portfolioMap: Map<number, ExistingAccountPortfolioPurposeMappingTable> = new Map();
                portfolioMap.set(portfolio.portfolioId, portfolio);
                accountAndModifiedPortfolioMap.set(portfolio.accountId, portfolioMap);
            }
        });
        this.existingAccountsService.updateModifiedExistingAccountPortfolioMap(accountAndModifiedPortfolioMap);
        this.existingAccountsService.applyMappedPortfoliosToProposalAccounts();
    }

    public onModalCancel(): void {
        this.mapPortfolioPurpose = [];
        this.portfolioMappingIssueModal.hideModalBox();
        this.cancelClicked.emit(true);
    }

    public getAccountGroupTypeIdFromAccountTypeName(accountName: string): number {
        const selectedAccountType: AccountType = this.accountTypes.find((type) => type.accountTypeDescription === accountName);
        return selectedAccountType.groupType.groupTypeId;
    }

    public createDropdownItemsForPortfolioTypes(nonModelPortfolios: NonModelPortfolioTypeResponse,
            accountId: number): OrderedSet<DropdownItem> {
        let portfolioPurposeDropdownList: OrderedSet<DropdownItem> = OrderedSet([]);
        nonModelPortfolios.nonModelPortfolioTypes
            .filter((nonModelPortfolioType) => !this.getAlreadyUsedPurposes(accountId).has(nonModelPortfolioType.portfolioPurposeId))
            .forEach((portfolioType) => {
            const dropdownItem: DropdownItem = new DropdownItem(portfolioType.portfolioPurposeId, portfolioType.portfolioPurposeName);
            portfolioPurposeDropdownList = OrderedSet([...portfolioPurposeDropdownList.toArray(), dropdownItem]);
        });
        return portfolioPurposeDropdownList;
    }

    public onCheckBoxValueChange(checkBoxValue: boolean, index: number) {
        this.mapPortfolioPurpose[index].isDeleted = !checkBoxValue;
        this.mapPortfolioPurpose[index].selectedPortfolioType = undefined;
        this.mapPortfolioPurpose[index].selectedPortfolioPurpose = undefined;
        this.enableOrDisablePurposes(index);
        this.validatePortfolioMappingTable();
    }

    public onPortfolioDropdownChange(portfolioPurposeType: OrderedSet<DropdownItem>, index: number) {
        const accountBeingUpdated = this.mapPortfolioPurpose[index].accountId;
        const portfolioIdBeingUpdated = this.mapPortfolioPurpose[index].portfolioId;
        const currentAccountPortfolioPurpose = this.nonModelPortfolioPurposeResponseMap.get(accountBeingUpdated);
        this.mapPortfolioPurpose
            .filter((portfolioTable) => portfolioTable.accountId === accountBeingUpdated)
            .forEach((portfolio) => {
            if (portfolioPurposeType) {
                if (portfolioIdBeingUpdated === portfolio.portfolioId) {
                    portfolio.selectedPortfolioType = portfolioPurposeType;
                    portfolio.selectedPortfolioPurpose = currentAccountPortfolioPurpose.nonModelPortfolioTypes
                        .filter((purpose) => portfolioPurposeType.first().id === purpose.portfolioPurposeId)[0];
                }
            } else {
                if (portfolioIdBeingUpdated === portfolio.portfolioId) {
                    portfolio.selectedPortfolioPurpose = undefined;
                    portfolio.selectedPortfolioType = undefined;
                }
            }
        });
        this.enableOrDisablePurposes(index);
        this.validatePortfolioMappingTable();
    }

    private enableOrDisablePurposes(index: number) {
        const otherPurposesSelectedForThisAccount: Set<number> = new Set();
        // Gather all the purposes that are selected for this account
        this.mapPortfolioPurpose
            .filter((portfolioTable) => portfolioTable.accountId === this.mapPortfolioPurpose[index].accountId)
            .forEach((portfolio) => {
                if (portfolio.selectedPortfolioType && !this.isPurposeReusable(Number(portfolio.selectedPortfolioType.first().id))) {
                    otherPurposesSelectedForThisAccount.add(Number(portfolio.selectedPortfolioType.first().id));
                }
        });
        // If all purposes disabled, mark portfolio as deleted, update the purposes that are already selected for this account
        this.mapPortfolioPurpose
            .filter((portfolioTable) => portfolioTable.accountId === this.mapPortfolioPurpose[index].accountId)
            .forEach((portfolio) => {
                // Reset delete flag
                portfolio.shouldDisableDeleteOption = false;
                const allPurposesUsed = portfolio.portfolioTypes
                    .every((purpose) => otherPurposesSelectedForThisAccount.has(Number(purpose.id)));
                // If all purposes are used, mark portfolio as deleted and can't be changed
                if (_.isNil(portfolio.selectedPortfolioType) && allPurposesUsed) {
                    portfolio.isDeleted = true;
                    portfolio.shouldDisableDeleteOption = true;
                } else {
                    portfolio.portfolioTypes.forEach((purpose) => {
                            if (portfolio.selectedPortfolioType &&
                                Number(portfolio.selectedPortfolioType.first().id) === Number(purpose.id)) {
                                purpose.disabled = false;
                            } else {
                                purpose.disabled = otherPurposesSelectedForThisAccount.has(Number(purpose.id));
                            }
                    });
                }
        });
    }

    public validatePortfolioMappingTable() {
        this.disableContinueButton = this.mapPortfolioPurpose.some((portfolio) =>
            portfolio.selectedPortfolioPurpose === undefined && !portfolio.isDeleted);
    }

    private navigateAwayFromModal(isModalPresentForRefresh: boolean): void {
        this.cancelClicked.emit(true);
        this.existingAccountsService.updateModifiedExistingAccountPortfolioMap(new Map());
        if (isModalPresentForRefresh) {
            this.existingAccountsService.showMappingModalForForceRefresh.next(false);
            this.delegationService.publish(CommandText.ExistingAccountsRefreshCompleted);
        } else {
            this.router.navigate([`/Proposal/WIP/${this.proposalId}/Scenarios/${this.scenarioId}/ProposedAccounts`]);
        }
    }

    public getAlreadyUsedPurposes(accountId: number): Set<number> {
        const alreadyUsedPurposes: Set<number> = new Set();
        const selectedAccount: ExistingAccountData = this.existingAccountDataMap.get(accountId);
        const eligiblePortfolios =
            this.existingAccountsService.filterOutPortfoliosWithNoMarketValue(selectedAccount.existingNonModelPortfolios);
        eligiblePortfolios.forEach((portfolio) => {
            const isPurposeNotReusable: boolean = !this.isPurposeReusable(portfolio.portfolioPurpose.portfolioPurposeId);
            if (isPurposeNotReusable) {
                alreadyUsedPurposes.add(portfolio.portfolioPurpose.portfolioPurposeId);
            }
        });
        return alreadyUsedPurposes;
    }

    public isPurposeReusable(purposeId: number): boolean {
        return purposeId === PortfolioPurpose.AdvisorGuided;
    }
}
