<div class="worksheet-footer gradient-standard container-fluid animated delay-500 faster fadeInUp">
    <div class="row align-items-end"
         [class.flex-container]="flexContainerVisibility">
        <div class="col-sm-4">
            <sei-button buttonText="Cancel"
                        buttonLeftFontAwesomeIcon="fas fa-times-circle"
                        buttonClass="btn-default"
                        (buttonClicked)="onCancelClick()">
            </sei-button>
        </div>

        <sei-car-paging-next-previous-button [hidden]="isHidePagingNextPreviousButton">
        </sei-car-paging-next-previous-button>


        <div class="col-sm-4 text-right"
             [class.col-sm-offset-4]="isOffsetVisibility">
            <sei-button *ngIf="functionWIP === 'PROPOSAL' && !showProposedScenario"
                        id="propose-scenario-button"
                        buttonText="Next"
                        buttonRightFontAwesomeIcon="fas fa-arrow-circle-right"
                        buttonClass="btn-default"
                        [buttonDisabled]="disableProposedScenarioButton"
                        (buttonClicked)="onProposedScenarioClick()">
            </sei-button>&nbsp;
            <sei-button *ngIf="functionWIP === 'PROPOSAL' && showProposedScenario"
                        id="start-button"
                        buttonText="Start"
                        buttonRightFontAwesomeIcon="fas fa-arrow-circle-right"
                        buttonClass="btn-default"
                        [buttonDisabled]="!enableCreateProposalButton"
                        (buttonClicked)="onCreateProposalClick()">
            </sei-button>&nbsp;
            <sei-button *ngIf="isSaveAndExitButtonVisible"
                        buttonText="Save & Exit"
                        buttonRightFontAwesomeIcon="fas fa-save"
                        buttonClass="btn-default"
                        [buttonDisabled]="isSaveAndExitButtonDisabled()"
                        [tooltip]="getSaveAndExitTooltip()"
                        container="body"
                        (buttonClicked)="onSaveAndExitAccountClick()">
            </sei-button>&nbsp;
            <sei-button *ngIf="isAddSelectedAccountsButtonVisible"
                        [buttonDisabled]="selectedAccountsButtonDisabled()"
                        [buttonText]="getSelectAccountsButtonText()"
                        [buttonRightFontAwesomeIcon]="getSelectAccountsButtonIcon()"
                        buttonClass="btn-default"
                        [tooltip]="getAddSelectedAccountsToolTip()"
                        container="body"
                        (buttonClicked)="onAddSelectedAccountsClick()">
            </sei-button>
        </div>
    </div>
</div>
