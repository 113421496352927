<div>
    <div class="pick-list-notification">
        <span class="notification-text">
            <span class="helper-text">
                <i class="fa-circle-info fa-fw fa-lg fa-solid"></i>
                <span *ngIf="numberOfAccountsSelected > 0">
                    {{ numberOfAccountsSelected }} {{ numberOfAccountsSelected > 1 ? 'accounts' : 'account' }} selected
                </span>
                <span *ngIf="numberOfAccountsSelected === 0">
                    Click on the available card(s) below in order to select them.
                </span>
            </span>
                <span class="select-text">
                    <span *ngIf="showSelectedAllButton()" class="notification-action" (click)="selectAllExistingAccounts()">Select All</span>
                    <span *ngIf="showSelectedAllButton()" class="pipe">|</span>
                    <span class="notification-action" (click)="deselectAllExistingAccounts()">Deselect All</span>
                </span>
        </span>
    </div>
</div>
