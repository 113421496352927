<section id="cover-page"
         class="us-letter-display">
    <div id="backgroundImage"
         *ngIf="isCoverImageDataVisible"
         class="background-image-variable"
         [class.shrink-background]="!isVerticalAccentBarVisible"
         [ngStyle]="{'background-image': 'url(' + coverImage + ')'}"></div>
    <div class="vert-strip-variable">
        <article class="customfor">
            <h2 class="verth2">Customized for</h2>
            <p>{{clientName}}</p>
        </article>
        <article class="presentby">
            <h2 class="verth2">Presented by</h2>
            <p>{{advisorName}}<br>
                {{firmName}}<br>
                {{advisorEmail}}<br>
                {{advisorPhone}}</p><br>
            <p>{{dateNow | date :'longDate'}}</p>
        </article>
        <div *ngIf="isVerticalAccentBarVisible"
             id="vert-strip-fill"
             [ngStyle]="verticalBarColorStyle">
        </div>
    </div>
    <div id="titleBar"
         *ngIf="coverTitleData.length > 0 && isCoverTitleDataVisible"
         class="title-bar-variable"
         [style.background-color]="titleBarColor">
        <h1 id="titleCopy"
                    class="cover-title"
                    [style.color]="titleBarTextColor">{{coverTitleData}}</h1>
    </div>
    <div class="pres-logo-variable"
         *ngIf="previewImage"><img alt="logo"
                     [src]="previewImage"></div>
        <div class="btn-expand">
            <i class="fas fa-expand-alt"
                   aria-hidden="true"></i>
        </div>
</section>
