/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdditionalActionLinkDisplay, DropdownItem } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { ElasticSearchConstants } from '@CarModels/constants';
import { AccountTypeOptionSettings } from '../../model/account-type-options';
import { CommandText, ContactTypes, ContactTypesDescription } from '../../model/enums';
import { Client } from '../../model/proposal';
import { ContactTypeUtilityService } from '../../service/contact-type-utility.service';
import { DelegationService } from '../../service/delegation.service';
import { ProposalAccountService } from '../../service/proposal-account.service';

@Component({
    selector: 'sei-car-search-owner-form',
    templateUrl: './proposal-search-owner-form.component.html',
    styleUrls: ['./proposal-search-owner-form.component.scss']
})
export class SearchOwnerFormComponent extends ParentComponentSubscriptionManager implements OnInit {
    private _index: number;
    @Input() client: Client;
    @Input() set index(value: number) {
        this._index = value;
        this.ownerLabel = `Owner ${this.index + 1}`;
    }
    get index(): number {
        return this._index;
    }

    @Input() set accountTypeId(value: number) {
        this._accountTypeId = value;
        this.initializeClientDropdownSet();
    }
    get accountTypeId(): number {
        return this._accountTypeId;
    }

    @Input() firmId: number;
    @Input() entitySearchEnabled?: boolean = true;
    @Input() clientList?: Client[];
    @Input() selectedClientList: Client[];
    @Input() isHeaderLabel: boolean = false;
    @Input() hideDeleteIcon: boolean = false;
    @Input() set typeOfOwnerId(value: number) {
        this._typeOfOwnerId = value;
        this.initializeClientDropdownSet();
    }
    get typeOfOwnerId(): number {
        return this._typeOfOwnerId;
    }
    @Input() requireTypeOfOwner: boolean = false;
    @Input() existingAccountContactTypeId: number;

    @Output() onClientChange: EventEmitter<Client> = new EventEmitter<Client>();
    @Output() onOwnerDelete: EventEmitter<number> = new EventEmitter<number>();

    public showElastic: boolean = true;
    public ownerLabel: string;
    public actionLinkDisplay: AdditionalActionLinkDisplay = AdditionalActionLinkDisplay.Always;
    public clientSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedClientSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public actionLinkText: string;
    public contactTypeId: number;
    public noResultsMessage: string;
    private _accountTypeId?: number;
    private _typeOfOwnerId?: number;

    constructor(private delegationService: DelegationService,
        private contactTypeUtilityService: ContactTypeUtilityService,
        private proposalAccountService: ProposalAccountService) {
        super('SearchOwnerFormComponent');
    }

    ngOnInit(): void {
        if (this.client.lastName !== undefined) {
            this.showElastic = false;
        }
        this.ownerLabel = `Owner ${this.index + 1}`;
        this.initializeClientDropdownSet();
        const hasEmptyFirstName: boolean = !this.client.firstName || this.client.firstName === '';
        const hasContactId: boolean = this.client.contactId && this.client.contactId > 0;
        if (hasEmptyFirstName && hasContactId) {
            this.client.contactType.id = ContactTypes.Organization;
            this.client.contactType.description = ContactTypesDescription.Organization;
        }
        this.subscriptions.push(this.delegationService.refresh().subscribe((commandText: CommandText) => {
            if (commandText === CommandText.RefreshClientList) {
                this.initializeClientDropdownSet();
            }
        }));
    }

    public onClientAdded(client: Client): void {
        if (client !== undefined) {
            this.client = client;
        }
        this.showElastic = false;
        this.emitClientChanges();
    }

    public onClientChanged(client: Client): void {
        this.client = client;
        this.emitClientChanges();
    }

    public onDeleteIconClicked(): void {
        this.onOwnerDelete.emit(this.index);
    }

    private emitClientChanges(): void {
        this.onClientChange.emit(this.client);
    }

    private initializeClientDropdownSet() {
        this.clientSet = OrderedSet([]);
        let filteredClientList = _.cloneDeep(this.clientList);
        if (this.existingAccountContactTypeId) {
            this.contactTypeId = this.existingAccountContactTypeId;
            filteredClientList = this.clientList.filter((client: Client) => client.contactType.id === this.contactTypeId);
        } else if (this.typeOfOwnerId) {
            this.contactTypeId = this.proposalAccountService.getContactTypeFromTypeOfOwner(this.typeOfOwnerId);
            filteredClientList = this.clientList.filter((client: Client) => client.contactType.id === this.contactTypeId);
        } else if (this.accountTypeId && this.accountTypeId > 0) {
            const accountTypeOptionSettings: AccountTypeOptionSettings =
                this.contactTypeUtilityService.getContactTypeFromAccountTypeId(this.accountTypeId);
            const contactType: ContactTypes = accountTypeOptionSettings.accountContactTypes[0];
            this.contactTypeId = this.contactTypeUtilityService.getContactType(contactType).id;
            filteredClientList = this.clientList.filter((client: Client) => client.contactType.id === this.contactTypeId);
        }
        if (!this.entitySearchEnabled && filteredClientList) {
            filteredClientList.forEach((clientItem: Client) => {
                const isClientSelected: boolean = this.selectedClientList &&
                    this.selectedClientList.filter((selectedClient: Client) => selectedClient.lastName !== undefined)
                        .some((selectedClient: Client) => selectedClient.contactId === clientItem.contactId);
                const dropdownItem: DropdownItem =
                    new DropdownItem(clientItem.contactId, clientItem.labelName, undefined, undefined, isClientSelected, undefined);
                this.clientSet = OrderedSet([...this.clientSet.toArray(), dropdownItem]);
            });
            this.actionLinkText = this.clientList.length < 10 ? 'Create New' : undefined;
        }
        this.noResultsMessage = filteredClientList?.length === 0 ? ElasticSearchConstants.NO_VALID_OWNERS :
            ElasticSearchConstants.NO_RESULTS_FOUND;
    }

    public onClientDropdownSelected(selectedItem: OrderedSet<DropdownItem>) {
        this.selectedClientSet = selectedItem;
        const selectedClient: Client = this.clientList.find((client: Client) => client.contactId === selectedItem.first().id);
        this.onClientAdded(selectedClient);
    }

}
