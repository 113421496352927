/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { UserProfileService } from '@CarServices/system/user-profile.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { CarUxLibModule } from '@sei/advisor-client-review-proposal-ux';
import { SeiCommonComponentsLibraryModule } from '@sei/common-components-lib-ux';
import { SeiCommonSwpComponentsLibraryModule } from '@sei/common-swp-components-lib-ux';
import {
    IasApplicationsLibUxModule,
    accountAssignmentDataSlice,
    crmRulesDataSlice,
    deleteInvestmentDataSlice,
    firmInfoSlice,
    investmentSummaryStateDataSlice
} from '@sei/ias-applications-lib-ux';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CurrencySuffixPipe } from 'projects/advisor-client-review-proposal-ux/src/lib/pipes/currency-suffix.pipe';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { DfsDrawdownChartComponent } from './charts/dfs-drawdown-chart/dfs-drawdown-chart.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditGoalContainerComponent } from './edit-goal-container/edit-goal-container.component';
import { EditProposalContainerComponent } from './edit-proposal-container/edit-proposal-container.component';
import { EditProposalComponent } from './edit-proposal-container/edit-proposal/edit-proposal.component';
import { ExistingAccountsCardComponent } from './existing-accounts/existing-accounts-card/existing-accounts-card.component';
import { ExistingAccountsFlyoutComponent } from './existing-accounts/existing-accounts-flyout.component';
import { ExistingAccountsFormComponent } from './existing-accounts/existing-accounts-form/existing-accounts-form.component';
import { FeeAnalysisComponent } from './fee-analysis/fee-analysis.component';
import { MdcProposalComponent } from './mdc-proposal/proposal.component';
import { PROPOSAL_ROUTES } from './proposal.routes';
import { ProposedAccountsCardComponent } from './proposed-accounts/proposed-accounts-card/proposed-accounts-card.component';
import { ProposedAccountsFlyoutComponent } from './proposed-accounts/proposed-accounts-flyout.component';
import { ProposedAccountsFormComponent } from './proposed-accounts/proposed-accounts-form/proposed-accounts-form.component';
import { ScenarioEditComponent } from './scenario-edit/scenario-edit.component';
import { fileUploadProvider } from './service/fileUploadProvider';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { WipCheckListComponent } from './wip-checklist/wip-checklist.component';
import { PocTreeMapComponent } from './presentation/PresentationPOC/tree-map-chart.component';
import { ExistingAccountsModelAlertComponent } from './existing-accounts/existing-accounts-model-alert/existing-accounts-model-alert.component';
import { AllAccountsComponent } from './all-accounts/all-accounts.component';
import { ProposedAccountsSectionComponent } from './proposed-accounts/proposed-accounts-section/proposed-accounts-section.component';
import { ExistingAccountsSectionComponent } from './existing-accounts/existing-accounts-section/existing-accounts-section.component';
import { ExistingAccountsPickListComponent } from './existing-accounts/existing-accounts-pick-list/existing-accounts-pick-list.component';
import { ExistingAccountsOwnerSectionComponent } from './existing-accounts/existing-accounts-owner-section/existing-accounts-owner-section.component';
import { ExistingAccountsPickListItemComponent } from './existing-accounts/existing-accounts-pick-list/existing-accounts-pick-list-item/existing-accounts-pick-list-item.component';
import { ExistingAccountsPickListNotificationComponent } from './existing-accounts/existing-accounts-pick-list/existing-accounts-pick-list-notification/existing-accounts-pick-list-notification.component';
import { ExistingAccountsPortfolioMappingComponent } from './existing-accounts/existing-accounts-portfolio-mapping/existing-accounts-portfolio-mapping.component';

@NgModule({
    imports: [
        CommonModule,
        CarUxLibModule.forRoot(),
        SeiCommonSwpComponentsLibraryModule.forRoot(),
        SeiCommonComponentsLibraryModule.forRoot(),
        IasApplicationsLibUxModule.forRoot(),
        HttpClientModule,
        BrowserAnimationsModule,
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DragDropModule,
        PopoverModule,
        ButtonsModule,
        TooltipModule,
        RouterModule.forRoot(
            PROPOSAL_ROUTES,
            {
                enableTracing: true, // <-- debugging purposes only
                useHash: true,
                preloadingStrategy: PreloadAllModules
            }),
        StoreModule.forFeature(
            'firmInfoSlice',
            firmInfoSlice
        ),
        StoreModule.forFeature(
            'accountAssignmentDataSlice',
            accountAssignmentDataSlice
        ),
        StoreModule.forFeature(
            'investmentSummaryStateDataSlice',
            investmentSummaryStateDataSlice
        ),
        StoreModule.forFeature(
            'deleteInvestmentDataSlice',
            deleteInvestmentDataSlice
        ),
        StoreModule.forFeature(
            'crmRulesDataSlice',
            crmRulesDataSlice
        ),
        ExistingAccountsModelAlertComponent
    ],
    exports: [RouterModule, ExistingAccountsPortfolioMappingComponent],
    providers: [
        fileUploadProvider,
        UserProfileService,
        Store
    ],
    declarations: [
        MdcProposalComponent,
        WipCheckListComponent,
        AccountDetailsComponent,
        DashboardComponent,
        ExistingAccountsFlyoutComponent,
        ExistingAccountsCardComponent,
        ExistingAccountsFormComponent,
        ExistingAccountsSectionComponent,
        ExistingAccountsPickListComponent,
        ExistingAccountsPortfolioMappingComponent,
        ExistingAccountsOwnerSectionComponent,
        ExistingAccountsPickListItemComponent,
        ExistingAccountsPickListNotificationComponent,
        FeeAnalysisComponent,
        ScenarioEditComponent,
        EditProposalContainerComponent,
        EditProposalComponent,
        EditGoalContainerComponent,
        ProposedAccountsFlyoutComponent,
        ProposedAccountsFormComponent,
        ProposedAccountsCardComponent,
        ProposedAccountsSectionComponent,
        WarningModalComponent,
        DfsDrawdownChartComponent,
        PocTreeMapComponent,
        AllAccountsComponent
    ]
})
export class ProposalModule { }
