/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { CommandText, ToolTipLabels, WipAccountStatus, WipChecklistLabels } from '../model/enums';
import { ToolTipOptionsFactory } from '../model/factory/tooltip-options-factory';
import { Account, Proposal, Scenario, WipCheckList } from '../model/proposal';
import { ToolTipOptions } from '../model/tooltip-options';
import { DelegationService } from '../service/delegation.service';
import { ProposalAccountService } from '../service/proposal-account.service';
import { ProposalService } from '../service/proposal.service';

@Component({
    selector: 'car-wip-checklist',
    templateUrl: './wip-checklist.component.html',
    styleUrls: ['./wip-checklist.component.scss']
})
export class WipChecklistComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public checkListSection: WipCheckList;

    @Input()
    public set proposal(value: Proposal) {
        this._proposal = value;
    }

    public get proposal(): Proposal {
        return this._proposal;
    }

    public showEditMode: boolean = false;
    public hideEditButton: boolean = false;
    public nameExist: boolean = false;
    public showOptionsPopover: boolean = false;
    public disableDeleteScenario: boolean = false;
    public totalProposalAccountBalance: number = 0;
    public scenarioId: number;
    private currentScenario: Scenario;
    private currentAccount: Account;
    private _proposal: Proposal;

    // TODO: For MVP1 we will hide all sections in checklist except for proposal account
    public hideSection: boolean = false;

    public wipAccountStatus: typeof WipAccountStatus = WipAccountStatus;

    public toolTipOptions: ToolTipOptions[] = [];
    public isCurrentAccountAddEnable: boolean = false;

    constructor(
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private proposalAccountService: ProposalAccountService,
        private router: Router
    ) {
        super('WipChecklistComponent');
    }

    public ngOnInit(): void {
        this.delegationService.refresh().subscribe((data: string) => {
            if (
                data === CommandText.UpdateWipCheckList &&
                this.checkListSection.sectionType ===
                WipChecklistLabels.ProposalScenario
            ) {
                this.setSectionStatus();
                this.isProposalContainsTaxableAccount();
            }
        });

        this.toolTipOptions = [
            new ToolTipOptionsFactory().createOption(
                ToolTipLabels.EditScenario
            ),
            new ToolTipOptionsFactory().createRemoveOption(
                ToolTipLabels.RemoveScenario,
                ToolTipLabels.RemoveScenarioTitle,
                ToolTipLabels.RemoveScenarioBody,
                this.proposal.scenarios.length <= 1
            )
        ];

        this.disableDeleteScenario = this.proposal.scenarios.length <= 1;

        this.hideSection = this.hideEditButton =
            this.checkListSection.name ===
            WipChecklistLabels.AssumptionsTransitionAnalysis ||
            this.checkListSection.name ===
            WipChecklistLabels.ClientRiskPreference;

        this.getCurrentData();
        this.isProposalContainsTaxableAccount();

        // Note: Show snipe for each section if complete or not complete; for now just verifying proposed scenario
        // Note: this.currentScenario is added to this statement because in the situation where we update the scenario name
        // first we update the proposal (put) and the wipchecklist scenario name is not updated with that api call,
        // its correct after we send the update for the wipchecklist (put)
        if (
            this.checkListSection.sectionType ===
            WipChecklistLabels.ProposalScenario && this.currentScenario
        ) {
            this.scenarioId = this.currentScenario.id;
            this.setSectionStatus();
        }
    }

    private getCurrentData(): void {
        this.currentScenario = this.proposal.scenarios[0];
        if (this.currentScenario) {
            this.checkListSection.subCheckList.forEach((checkList) => {
                const routeId = checkList.route.split('/')[1];
                this.currentScenario.accounts.forEach((account) => {
                    if (account.id === Number(routeId)) {
                        this.currentAccount = account;
                    }
                });
            });
        }
    }

    private setSectionStatus(): void {
        if (this.currentAccount) {
            this.checkListSection.mdcSnapShot.completed = this.proposalAccountService.verifyProposalAccountStatus(
                this.currentAccount
            );
        }
    }

    public onOptionSelected(event: ToolTipLabels): void {
        switch (event) {
            case ToolTipLabels.EditScenario:
                this.router.navigate([
                    `Proposal/WIP/${this.proposal.id}/Scenarios/${this.scenarioId}`
                ]);
                break;
            case ToolTipLabels.RemoveScenario:
                this.proposalService
                    .deleteProposalScenario(this.proposal.id, this.scenarioId)
                    .subscribe((result) => {
                        this.proposalService.changedProposal(
                            result.data['proposal']
                        );
                        this.router.navigate([`Proposal/Dashboard`]);
                    });
                break;
        }
    }

    public onEditConfirmClick(): void {
        if (this.checkListSection.name.length > 0) {
            const scenarioNameExists = this.proposalService.scenarioExists(
                this.checkListSection.name
            );
            if (scenarioNameExists) {
                if (
                    this.checkListSection.sectionType ===
                    WipChecklistLabels.ProposalScenario
                ) {
                    this.currentScenario.name = this.checkListSection.name;
                }
                this.showEditMode = false;
                this.nameExist = false;
            } else {
                this.showEditMode = true;
                this.nameExist = true;
            }
        }
    }

    public onRemoveNameClick(): void {
        this.checkListSection.name = '';
    }

    public onOptionsToggleClick(): void {
        this.showOptionsPopover = !this.showOptionsPopover;
    }

    public isProposalContainsTaxableAccount(): void {
        this.proposal.scenarios.forEach((scenario: Scenario): void => {
            scenario.accounts.forEach((account: Account): void => {
                if (!account.type.taxQualified && this.proposalAccountService.verifyProposalAccountStatus(
                    account) === 1) {
                    this.isCurrentAccountAddEnable = true;
                }
            });
        });
    }
}
