<sei-modal-window #portfolioMappingIssueModal 
                [modalType]="'lg'"
                [modalTitle]="'Portfolio Mapping Required'"
                [isBackdrop]="true"
                [isDraggable]="false"
                [footerEnable]="true"
                [isDismissible]="true"
                (modalClosed)="onModalCancel();">
        <div class="modal-body">
            <p>The portfolios on the following account(s) could not be mapped. Please select the portfolio type for each portfolio listed below or choose to remove it from the account.</p>
            <div class="row table-header sei-margin-bottom">
                <div class="col-xs-2 details-column header-text">
                    <span>Account Name</span>
                </div>
                <div class="col-xs-3 details-column header-text">
                    <span>Account Number</span>
                </div>
                <div class="col-xs-3 details-column header-text">
                    <span>Portfolio</span>
                </div>
                <div class="col-xs-3 details-column header-text">
                    <span>Portfolio Type</span>
                </div>
                <div class="col-xs-1 details-column header-text" style="padding-left: 0; text-align: center">
                    <span>Delete</span>
                </div>
            </div>
            <div class="row sei-margin-bottom" *ngFor="let portfolio of mapPortfolioPurpose; let i = index;">
                <div class="col-xs-2 account-name">{{portfolio.accountName}}</div>
                <div class="col-xs-3 account-number">{{portfolio.accountNumber}}</div>
                <div class="col-xs-3 portfolio-name">{{portfolio.portfolioName}}</div>
                <div class="col-xs-3">
                    <sei-dropdown id="portfolioTypeDropdown"
                                [items]="portfolio.portfolioTypes"
                                [showSelectionOptions]="true"
                                [disabled]="portfolio.isDeleted"
                                [ngModel]="portfolio.selectedPortfolioType"
                                (ngModelChange)="onPortfolioDropdownChange($event, i)">
                    </sei-dropdown>
                </div>
                <div class="col-xs-1 delete">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" 
                            [ngModel]="portfolio.isDeleted"
                            [disabled]="portfolio.shouldDisableDeleteOption"
                            (change)="onCheckBoxValueChange(portfolio.isDeleted, i)">
                    </div>
                </div>
            </div>
        </div>
        <footer class="modalbox-footer">
            <div class="pull-left">
                <sei-button buttonText="Cancel"
                            buttonClass="btn-default"
                            (buttonClicked)="onModalCancel()"></sei-button>
            </div>
            <div class="pull-right">
                <sei-button buttonText="Continue"
                            buttonClass="btn-primary"
                            [buttonDisabled]="disableContinueButton"
                            (buttonClicked)="onModalContinue()"></sei-button>
            </div>
        </footer>
</sei-modal-window>