<ng-template #nodeTemplateRef
             let-node
             let-i="index">
    <div class="row toggle-row"
         *ngIf="node.level === 1">
        <div class="col-xs-9 toggle-row-left indent_{{node.level}}">
            <ng-container *ngIf="node.level === 1">
                <h3>{{node.label}}</h3>
            </ng-container>
            <ng-container *ngIf="node.level > 1">
                {{node.label}}
            </ng-container>
        </div>
        <div class="col-xs-3 toggle-row-right">
            <sei-car-toggle-include-exclude id="toggle-{{i}}"
                                            *ngIf="node.level === 1 && node.isToggle"
                                            (ngModelChange)="parentToggleChange($event, node)"
                                            [(ngModel)]="node.include"
                                            [isReadUser]="isReadUser">
            </sei-car-toggle-include-exclude>
            <sei-icon-toggle *ngIf="node.level >= 2 && node.isToggle"
                             name="icon-toggle"
                             [(ngModel)]="node.include"
                             [trueIcon]="trueIcon"
                             [falseIcon]="falseIcon"
                             [iconClass]="iconClass"
                             [iconSize]="iconSize"
                             [offIsGrey]="offIsGrey"
                             [disabled]="disabled"
                             (ngModelChange)="changeToggle($event, node)">
            </sei-icon-toggle>
        </div>
    </div>
    <ng-container *ngIf="node.sections?.length">
        <ng-template ngFor
                     [ngForOf]="node.sections"
                     [ngForTemplate]="nodeTemplateRef">
        </ng-template>
    </ng-container>
</ng-template>

<div id="main-content"
     class="container-fluid">
    <car-generic-error-notification></car-generic-error-notification>
    <div class="row">
        <div class="col-xs-12">
            <h1>Presentation for {{presentationSetting.proposalName}}</h1>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-9 col-sm-10 col-xs-12">
            <form name="formSetting"
                  novalidate
                  #formSetting="ngForm"
                  class="group-gap">
                <h2>Presentation Settings</h2>
                <div class="row form-row">
                    <div class="col-xs-6 form-item">
                        <label for="firmDisplayName">Firm Display Name</label>
                        <input type="text"
                               id="firmDisplayName"
                               name="firmDisplayName"
                               class="form-control"
                               [readonly]="isReadUser || !allowCustomFirmNameOnProposal"
                               [class.alert]="firmDisplayName.touched && advisorDisplayName.invalid"
                               [(ngModel)]="presentationSetting.firmDisplayName"
                               placeholder="Firm's Name"
                               #firmDisplayName="ngModel"
                               maxlength="255"
                               required>
                        <car-alert-popover [hidden]="!firmDisplayName.touched || firmDisplayName.valid"
                                           [alertType]="alertPopoverTypes.Error"
                                           [alertMessage]="'Firm Display Name is required and cannot be blank'"></car-alert-popover>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-xs-6 form-item">
                        <label for="advisorDisplayName">Advisor Display Name (Optional)</label>
                        <input type="text"
                               id="advisorDisplayName"
                               name="advisorDisplayName"
                               class="form-control"
                               [readonly]="isReadUser"
                               [class.alert]="advisorDisplayName.touched && advisorDisplayName.invalid"
                               [(ngModel)]="presentationSetting.advisorDisplayName"
                               placeholder="Advisor's Name"
                               maxlength="255"
                               #advisorDisplayName="ngModel">
                    </div>
                </div>
                <div class="row toggle-row">
                    <div class="col-xs-9 toggle-row-left">
                        <p>Save as presentation default</p>
                    </div>
                    <div class="col-xs-3 toggle-row-right">
                        <sei-car-toggle-sei-wizard-nav [isReadUser]="isReadUser"
                                                       (onChangeEvent)="advisorDefaultToggleChange()"></sei-car-toggle-sei-wizard-nav>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-xs-6 form-item">
                        <label for="clientDisplayName">Client Display Name</label>
                        <input type="text"
                               id="clientDisplayName"
                               name="clientDisplayName"
                               class="form-control"
                               [readonly]="isReadUser"
                               [class.alert]="clientDisplayName.touched && clientDisplayName.invalid"
                               [(ngModel)]="presentationSetting.clientDisplayName"
                               placeholder="Client's Name"
                               #clientDisplayName="ngModel"
                               maxlength="4000"
                               required>
                        <car-alert-popover [hidden]="!clientDisplayName.touched || clientDisplayName.valid"
                                           [alertType]="alertPopoverTypes.Error"
                                           [alertMessage]="'Client Display Name is required and cannot be blank'"></car-alert-popover>
                    </div>
                </div>
                <section class="presentationOptions section-gap">
                    <h2>Presentation Options</h2>
                    <div *ngIf="isCurrentAccountsPresentInProposal"
                         class="row toggle-row">
                        <div class="col-xs-9 toggle-row-left">
                            <p>Include Current vs. Proposed Comparison</p>
                        </div>
                        <div class="col-xs-3 toggle-row-right">
                            <sei-car-toggle-sei-wizard-nav [isReadUser]="isReadUser"
                                                           [isDisabled]="isIncludeCurrentVsProposedToggleLocked"
                                                           [value]="presentationSetting.isIncludeExistingAccountsComparisonInPresentation"
                                                           (onChangeEvent)="includeExistingAccountsToggleChange()"></sei-car-toggle-sei-wizard-nav>
                        </div>
                    </div>

                    <ng-template [ngTemplateOutlet]="nodeTemplateRef"
                                 [ngTemplateOutletContext]="{ $implicit: presentationSetting }">
                    </ng-template>
                </section>
                <section class="documentCreation section-gap">
                    <car-document-selection [hidden]="'true'"
                                            [documentSelection]="presentationSetting.documentCreationOption"
                                            (documentClick)='documentSelectionClick($event)'></car-document-selection>
                </section>

                <div class="row button-gap">
                    <div class="col-xs-6">
                        <sei-button buttonText="Back"
                                    buttonLeftFontAwesomeIcon="fas fa-arrow-circle-left"
                                    buttonClass="btn-default"
                                    [routerLink]="['/Proposal/WIP/', proposalId]">
                        </sei-button>
                    </div>
                    <div class="col-xs-6 text-right">
                        <sei-button buttonText="Next"
                                    buttonRightFontAwesomeIcon="fas fa-arrow-circle-right"
                                    buttonClass="btn-default"
                                    [buttonDisabled]="!clientDisplayName.valid || disableNextForLoad || !firmDisplayName.valid"
                                    (buttonClicked)="nextClick()">
                        </sei-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
