/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { PropertyService } from '@CarServices/system/property.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, AlertPopoverTypes, PresentationSection, PresentationSettingService, PresentationSettings, Proposal, ProposalService, UserLevelEntitlement, UserProfile, UserProfileService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { DisplayPreference } from 'projects/advisor-client-review-proposal-ux/src/lib/model/presentation-settings';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {

    public presentationSetting: PresentationSettings = {};

    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;

    public proposalId: number;

    // Note: settings for sei-icon-toggle
    public offIsGrey: boolean = false;
    public disabled: boolean = false;
    public isActive: boolean = true;
    public trueIcon: string = 'fa-fw fas fa-toggle-on fa-rotate-180';
    public falseIcon: string = 'fa-fw fas fa-toggle-off fa-rotate-180';
    public iconSize: string = 'fa-2x';
    public iconClass: string = 'info';
    public isReadUser: boolean = false;
    public allowCustomFirmNameOnProposal: boolean = false;
    public disableNextForLoad: boolean = false;
    public isCurrentAccountsPresentInProposal: boolean = false;
    public isIncludeCurrentVsProposedToggleLocked: boolean = false;

    public advisorDisplayPreference: string;
    private advisorId: number;
    private saveAdvisorDefault: boolean = false;

    constructor(
        private presentationSettingService: PresentationSettingService,
        private route: ActivatedRoute,
        private router: Router,
        private userProfileService: UserProfileService,
        private userProfileRepository: UserProfileRepository,
        private proposalService: ProposalService,
        private spinnerService: SpinnerService,
        private propService: PropertyService,
        private featureFlagService: FeatureFlagService
    ) {
        super('SettingsComponent');
    }

    public ngOnInit(): void {
        this.spinnerService.start();
        this.isReadUser = this.userProfileService.isReadUser;
        this.subscriptions.push(this.route.paramMap.subscribe((params) => {
            this.proposalId = Number(params.get('proposalId'));
            this.subscriptions.push(this.proposalService.currentProposal.subscribe((proposal: Proposal) => {
                this.proposalService.checkIfScenarioHasAnyExistingAccounts(proposal);
                this.isCurrentAccountsPresentInProposal = this.proposalService.isExistingAccountsPresentOnProposal;
                this.advisorId = this.proposalService.getProposalPrimaryAdvisor(proposal).entityId;
                if (this.propService.exists(() => proposal)) {
                    if (this.advisorId) {
                        this.setProcessingRuleForFirmName(this.advisorId);
                        this.subscriptions.push(this.presentationSettingService
                            .getPresentationSettingById(this.proposalId, this.advisorId).subscribe(
                                (response: PresentationSettings) => {
                                    if (!response) {
                                        this.subscriptions.push(this.presentationSettingService
                                            .initializePresentationSetting(this.proposalId, this.isCurrentAccountsPresentInProposal)
                                            .subscribe((presentationSettingResponse) => {
                                                this.presentationSetting = presentationSettingResponse;
                                                this.checkIfProposalContainsOnlyUnmodifiedExistingAccounts(proposal);
                                            }));
                                    } else if ((response.advisorDisplayName || response.firmDisplayName) && !response.clientDisplayName) {
                                        this.subscriptions.push(this.presentationSettingService
                                            .initializePresentationSetting(this.proposalId, this.isCurrentAccountsPresentInProposal)
                                            .subscribe((presentationSettingResponse) => {
                                                this.presentationSetting = presentationSettingResponse;
                                                this.checkIfProposalContainsOnlyUnmodifiedExistingAccounts(proposal);
                                                if (response.advisorDisplayName || response.advisorDisplayName.trim() === '') {
                                                    this.presentationSetting.advisorDisplayName = response.advisorDisplayName;
                                                }
                                                if (response.firmDisplayName && this.allowCustomFirmNameOnProposal) {
                                                    this.presentationSetting.firmDisplayName = response.firmDisplayName;
                                                }
                                            }));
                                    } else {
                                        if (!this.allowCustomFirmNameOnProposal) {
                                            response.firmDisplayName = this.userProfileService.getCurrentUserProfile.firm.firmName;
                                        }
                                        this.presentationSetting = response;
                                        this.checkIfProposalContainsOnlyUnmodifiedExistingAccounts(proposal);
                                        this.presentationSetting.advisorDisplayName = response.advisorDisplayName;
                                        if (this.presentationSetting.isIncludeExistingAccountsComparisonInPresentation === undefined ||
                                            !this.isCurrentAccountsPresentInProposal) {
                                            this.presentationSetting.isIncludeExistingAccountsComparisonInPresentation = false;
                                        }
                                    }
                                }
                            ));
                    }
                }
            }));
        }));
    }

    private checkIfProposalContainsOnlyUnmodifiedExistingAccounts(proposal: Proposal): void {
        if (this.featureFlagService.checkExistingAccountsSectionEnabled() && this.isCurrentAccountsPresentInProposal) {
            const allAccounts: Account[] = proposal?.scenarios[0]?.accounts;
            const onlyUnmodifiedExistingAccounts: boolean =
                allAccounts?.every((account) => account?.currentAccountNumber && !account?.isCurrentAccountInvestmentsModified);
            if (onlyUnmodifiedExistingAccounts) {
                this.presentationSetting.isIncludeExistingAccountsComparisonInPresentation = false;
                this.isIncludeCurrentVsProposedToggleLocked = true;
            }
        }
    }

    private setProcessingRuleForFirmName(primaryAdvisorForProposalId: number) {
        const currentUserProfile: UserProfile = this.userProfileService.getCurrentUserProfile;
        const isInstanceUser: boolean = currentUserProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            currentUserProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;
        if (isInstanceUser) {
            // at this time, it is required to pull details about the primary advisor if we're in emulation mode
            this.subscriptions.push(this.userProfileRepository.read(primaryAdvisorForProposalId).subscribe(
                (userProfile: UserProfile) => {
                    userProfile.processingRules.AllowCustomFirmNameOnProposal === 'Yes' ?
                        this.allowCustomFirmNameOnProposal = true :
                        this.allowCustomFirmNameOnProposal = false;
                    this.spinnerService.stop();
                },
                (error) => {
                    this.spinnerService.stop();
                }
            ));
        } else {
            currentUserProfile.processingRules.AllowCustomFirmNameOnProposal === 'Yes' ?
                this.allowCustomFirmNameOnProposal = true : this.allowCustomFirmNameOnProposal = false;
        }

    }

    public documentSelectionClick(selection: number) {
        this.presentationSetting.documentCreationOption = selection;
    }

    public selectAllChange(event, section: PresentationSection) {
        if (event) {
            section.sections.forEach((presentationSection) => {
                presentationSection.sections.forEach((child) => child.include = event);
            });
        }
    }

    public changeToggle(event, section: PresentationSection) {
        if (!event) {
            // Reset the include all flag it will be 2 level deep.
            // Get level 2 section
            const parentSections: PresentationSection[] = [];

            this.presentationSetting.sections.forEach((parent) => {
                parentSections.push(parent.sections.find((child) => child.level === 2));
            });

            let matchedSection: PresentationSection = null;

            parentSections.forEach((parent) => {
                parent.sections.forEach((child) => {
                    if (child.sections.includes(section)) {
                        matchedSection = parent;
                    }
                });
            });

            this.presentationSetting.sections.forEach((parent) => {
                if (parent.sections.includes(matchedSection)) {
                    parent.sections[0].include = event;
                }
            });
        }

        // Note: So if Include all accounts in proposal or ips then mark all children true.
        if (section.level === 2 && event) {
            this.setNestedSections(section.sections, 3, true);
        } else {
            this.setNestedSections(section.sections, 3, false);
        }
    }

    public parentToggleChange(event, section: PresentationSection) {
        if (!event) {
            // Note: This will set the other section true if this parent section is set false
            this.setOtherParentSections(section, true);
            this.setNestedSections(section.sections, 2, false);
        }
    }

    private setOtherParentSections(section: PresentationSection, flag: boolean) {
        let parentSections: PresentationSection[] = [];
        parentSections = this.presentationSetting.sections.filter((parent) => parent.level === 1);
        let foundIndex = parentSections.findIndex((sectionItem) => sectionItem === section);
        parentSections.splice(foundIndex, 1);
        parentSections.forEach((element) => {
            foundIndex = this.presentationSetting.sections.findIndex((sectionItem) => sectionItem === element);
            if (foundIndex >= 0) {
                this.presentationSetting.sections[foundIndex].include = flag;
            }
        });
    }

    public advisorDefaultToggleChange(): void {
        this.saveAdvisorDefault = !this.saveAdvisorDefault;
    }

    public includeExistingAccountsToggleChange(): void {
        this.presentationSetting.isIncludeExistingAccountsComparisonInPresentation =
            !this.presentationSetting.isIncludeExistingAccountsComparisonInPresentation;
    }

    public nextClick(): void {
        this.disableNextForLoad = true;
        const displayPreferenceRequest: DisplayPreference =
        {
            advisorId: this.advisorId,
            advisorFirmDisplayName: this.presentationSetting.firmDisplayName,
            advisorDisplayName: this.presentationSetting.advisorDisplayName
        };

        if (this.saveAdvisorDefault) {
            this.subscriptions.push(this.presentationSettingService.saveAdvisorDisplayPreferences(displayPreferenceRequest).subscribe());
        }

        if (!this.isCurrentAccountsPresentInProposal) {
            this.presentationSetting.isIncludeExistingAccountsComparisonInPresentation = false;
        }
        this.presentationSettingService.savePresentationSetting(this.presentationSetting).subscribe(
            () => {
                this.disableNextForLoad = false;
                this.router.navigate(['/Proposal/Presentation/CheckList/' + this.proposalId]);
            },
            (error) => {
                this.disableNextForLoad = false;
            }
        );
    }

    private setNestedSections(arr: PresentationSection[], parent?: number, flag?: boolean) {

        // if we didnt get a depth, start at 2
        // const depth = parent || 2;
        const out = [];
        for (const i in arr) {
            if (arr[i].level === parent) {
                const children = this.setNestedSections(arr[i].sections, arr[i].level + 1);

                if (children.length) {
                    // arr[i].children = children;
                    children.forEach((child) => child.include = flag);
                }
                out.push(arr[i]);
            }
        }
        return out;
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
        }
    }
}
