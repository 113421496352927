<div class="container"
     *ngIf="proposal">
    <sei-alert *ngIf="isChecklistLocked() && !isForceRefreshRequired()"
               [type]="infoType"
               class="banner-message-info">{{getLockedProposalAlertMessage()}} To make changes, please <a class="bold" (click)="onReactivateClick()">reactivate</a> the proposal.</sei-alert>
    <div class="row checklist-top"
         [ngClass]="{'standard-bottom-margin': showAdvisorChangeNotification(proposal)}">
        <div class="col-xs-12">
            <hr>
            <h5 *ngIf="!showEditMode; else EditMode">{{proposal?.name}}
            <sei-car-action-tooltip-popover [isChecklistLocked]="isChecklistLocked()"
                                            [toolTipOptions]="toolTipOptions"
                                            (onOptionSelected)="onOptionSelected($event, proposal.id)"
                                            (onRemoveConfirm)="onOptionSelected($event, proposal.id)">
            </sei-car-action-tooltip-popover>
            </h5>
            <ng-template #EditMode>
                <h5>
                    <sei-car-inline-edit-component [inputText]="proposal?.name"
                        (editionConfirmed)="onEditionConfirmed($event, proposal.id)">
                    </sei-car-inline-edit-component>
                </h5>
            </ng-template>
            <hr>
        </div>
    </div>
    <sei-alert [type]="getInfoTypeForRefreshAlert()"
               class="banner-message-info refresh-banner"
               *ngIf="showRefreshBanner()">
        <div>
            <span *ngIf="showAsOfDateMessage()">
                Market Value and price data is as of {{ dateTimeForPipe | seiDateTime: format }}. For updated values click the Refresh button. Clicking refresh will reset existing account information.
            </span>
            <span *ngIf="isForceRefreshRequired()">
                {{ getRefreshAlertBannerMessage() }}
            </span>
            <sei-button buttonText="Refresh"
                            buttonRightFontAwesomeIcon="fas fa-refresh"
                            buttonClass="btn-default"
                            buttonSize="btn-sm"
                            (buttonClicked)="onRefreshClick()"></sei-button>
        </div>
    </sei-alert>
    <div id="checklist-wip"
         class="row">
        <div class="col-xs-12">
            <car-generic-error-notification></car-generic-error-notification>
            <div id="main-content">
                <div *ngIf="showAdvisorChangeNotification(proposal)">
                    <sei-alert [type]="bannerMessageTypes.danger">
                        <div [innerHTML]="'Invalid Advisor(s) on Account(s). To proceed, replace Advisor(s).'">
                        </div>
                    </sei-alert>

                </div>
                <car-wip-checklist *ngFor="let checkListSection of proposal?.wipCheckList"
                                   [checkListSection]="checkListSection"
                                   [proposal]="proposal">
                </car-wip-checklist>

                <label *ngIf="false"
                       class="btn btn-default button-gap">Add Proposed Scenario</label>
                <hr>
                <car-wip-checklist-footer [enablePresentationButton]="enablePresentationButton"
                                          [isReadUser]="isReadUser"
                                          [isChecklistLocked]="isChecklistLocked()"
                                          [isForceRefreshRequiredForCurrentAccounts]="isForceRefreshRequired()"
                                          class="gap">
                </car-wip-checklist-footer>

            </div>
        </div>
        <car-implement-modal *ngIf="showImplementModal"
                             [proposal]="minDataProposal"
                             [isProposalGenerated]="isProposalGenerated"
                             [hideChecklistLink]="true"
                             (onImplement)="implement($event)"
                             (onCancel)="cancel()">
        </car-implement-modal>
        <sei-car-reactivate-modal *ngIf="showReactivateModal"
                                  [proposal]="minDataProposal"
                                  [proposalStatusResponse]="proposalAccountStatuses"
                                  (onContinueClicked)="submitReactivation($event)"
                                  (modalClosed)="onReactivateModalClosed()"></sei-car-reactivate-modal>
        <sei-modal-window modalType="modal-sm"
                          modalTitle="Refresh Values"
                          [footerEnable]="true"
                          [isBackdrop]="true"
                          #refreshModal>
            <div class="modal-body">
                <p>{{ getRefreshModalMessage() }}</p>
            </div>
            <footer class="modalbox-footer">
                <div class="pull-left">
                    <sei-button buttonText="Cancel"
                                buttonClass="btn-default"
                                (buttonClicked)="onRefreshCancel()"></sei-button>
                </div>
                <div class="pull-right">
                    <sei-button buttonText="Continue"
                                buttonClass="btn-primary"
                                (buttonClicked)="onRefreshConfirm()"></sei-button>
                </div>
            </footer>
        </sei-modal-window>
        <router-outlet></router-outlet>
    </div>
</div>

<sei-car-existing-accounts-portfolio-mapping *ngIf="showMappingModal"
    [showModal]="true"
    [isModalForForceRefresh]="true"
    [selectedExistingAccounts]="getSelectedExistingAccountsForPortfolipMapping()"
    (cancelClicked)="cancelMappingModal()">
</sei-car-existing-accounts-portfolio-mapping>