/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ImplementProposalConstants } from '@CarModels/constants';
import { ImplementModalState } from '@CarModels/enums';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ImplementModalService } from '@CarServices/implement-modal/implement-modal.service';
import { GlobalService } from '@CarServices/system/global.service';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter, Input,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import { BannerMessageType, DisplayAmountOptions, DisplayNullOptions, DisplayNumericScaleSuffix, DropdownItem, ModalWindowComponent, NumericScale } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { PrintExportComponent } from '@sei/common-swp-wijmo-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { CommandText, PostProposalImplementRedirectActions, UserLevelEntitlement } from '../model/enums';
import { Account, ImplementProposalOptions, ImplementedAccountStatus, MinDataProposal } from '../model/proposal';
import { OfficeLocation, UserProfile } from '../model/user-profile';
import { DelegationService } from '../service/delegation.service';
import { UserProfileService } from '../service/user-profile.service';

@Component({
    selector: 'car-implement-modal',
    templateUrl: './implement-modal.component.html',
    styleUrls: ['./implement-modal.component.scss']
})
export class ImplementModalComponent extends ParentComponentSubscriptionManager implements AfterViewInit, OnInit {
    @Input()
    public proposal: MinDataProposal;
    @Input()
    public enableImplementButton: boolean = true;
    @Input()
    public hideChecklistLink: boolean = false;
    @Input()
    public isProposalGenerated: boolean = false;

    @Output()
    public onImplement = new EventEmitter<{
        id: number;
        redirectAction: string;
        implementProposalOptions?: ImplementProposalOptions;
    }>();
    @Output()
    public onCancel = new EventEmitter<number>();

    @ViewChild('implementModal', { static: false })
    public implementModalComponent: ModalWindowComponent;
    public selectedValue: string = null;
    public postProposalImplementRedirectActions: typeof PostProposalImplementRedirectActions = PostProposalImplementRedirectActions;
    public informationMessageForNonGeneratedProposals: string = ImplementProposalConstants.NOT_GENERATED_PROPOSAL_INFORMATION_MESSAGE;
    public informationMessageForExistingAccounts: string = ImplementProposalConstants.ONLY_EXISTING_ACCOUNTS_INFORMATION_MESSAGE;
    public informationMessageForBothAccountCategories: string = ImplementProposalConstants.BOTH_ACCOUNT_CATEGORIES_INFORMATION_MESSAGE;

    // DAO Form fields
    public officeLocations: OfficeLocation[];
    public showOfficeLocations: boolean;
    public officeLocationDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedOfficeLocationDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
    public implementProposalOptions: ImplementProposalOptions = {
        officeLocationId: undefined,
        advisorGroupId: undefined
    };
    public officeLocationDescription: string = '';
    // sei-alert properties
    public errorType: BannerMessageType = BannerMessageType.danger;
    public successType: BannerMessageType = BannerMessageType.success;
    // sei-pageheader properties
    public proposalDetailsHeaderProperties: SubpageheaderModel = {
        title: 'Proposal Details',
        switcherAvailable: false
    };
    public accountDetailsHeaderProperties: SubpageheaderModel = {
        title: 'Account Details (Draft Account)',
        switcherAvailable: false
    };
    // sei-print-export
    @ViewChild('printExport') printExportComponent: PrintExportComponent;
    // seiDisplayMonetaryAmount properties
    public displayNumericScaleSuffix: DisplayNumericScaleSuffix = {
        scale: NumericScale.NULL,
        displayDecimals: 2
    };
    public roundFractionalNumber: boolean = false;
    public amountOptions: DisplayAmountOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        roundFractionalAmount: false
    };

    public isPrinting: boolean = false;

    private userProfile: UserProfile;
    private isSubmissionLoading: boolean = false;

    constructor(private userProfileService: UserProfileService,
        private changeDetector: ChangeDetectorRef,
        private implementModalService: ImplementModalService,
        private featureFlagService: FeatureFlagService,
        private delegationService: DelegationService) {
        super('ImplementModalComponent');
    }

    ngOnInit(): void {
        this.implementModalService.resetModalState();
        this.subscribeToLoadingState();
        this.userProfile = this.userProfileService?.getCurrentUserProfile;
        this.getOfficeLocations();
        this.implementProposalOptions.proposalId = this.proposal.proposalId;
    }

    ngAfterViewInit(): void {
        this.implementModalComponent.showModalBox();
    }

    public optionSelectedChange(event) {
        this.selectedValue = event.target.value;
    }

    public cancel() {
        if (this.isConfirmationPage()) {
            this.implementModalService.refreshDashboardProposals();
            this.implementModalService.resetRefreshSubject();
        }
        this.implementModalService.resetModalState();
        this.onCancel.emit(this.proposal.proposalId);
        this.delegationService.publish(CommandText.EnableWipImplementProposalButton);
    }

    public implement() {
        this.implementModalService.setIsLoading(true);
        this.implementModalService.setValidationErrors([]);
        if (!this.hasAtLeastOneProposedAccount()) {
            this.selectedValue = PostProposalImplementRedirectActions.Dashboard;
        }
        this.onImplement.emit({
            id: +this.proposal.proposalId,
            redirectAction: this.selectedValue,
            implementProposalOptions: this.implementProposalOptions
        });
    }

    public get isValid(): boolean {
        const officeLocationValid: boolean = this.showOfficeLocations ? !!this.implementProposalOptions.officeLocationId : true;
        return this.selectedValue === PostProposalImplementRedirectActions.Account ?
            this.selectedValue !== null && officeLocationValid : this.selectedValue !== null;
    }

    public showAdvisorGroupIdInput(): boolean {
        return this.proposal?.proposalLevelAdvisors?.length > 1 && this.proposal.proposalAccounts.length > 0;
    }

    public onAdvisorGroupIdChange(advisorGroupId: string): void {
        const previousGroupId: string = _.cloneDeep(this.implementProposalOptions.advisorGroupId);
        this.implementProposalOptions.advisorGroupId = advisorGroupId;
        // Force change detection to allow sanitized string to make its way back to the template
        this.changeDetector.detectChanges();
        if (advisorGroupId === advisorGroupId.replace(ImplementProposalConstants.VALID_CHARACTER_REGEX_FOR_DAO, '')) {
            this.implementProposalOptions.advisorGroupId =
                advisorGroupId.replace(ImplementProposalConstants.VALID_CHARACTER_REGEX_FOR_DAO, '');
        } else {
            this.implementProposalOptions.advisorGroupId = _.cloneDeep(previousGroupId);
        }

    }

    public getOfficeLocations(): void {
        const isInstanceUser: boolean = this.userProfile?.entitlements?.userLevelId === UserLevelEntitlement.PO ||
            this.userProfile?.entitlements?.userLevelId === UserLevelEntitlement.Instance;
        this.officeLocations = isInstanceUser ? this.userProfile?.firm?.locations : this.userProfileService?.firm?.locations;
        this.showOfficeLocations = this.officeLocations && this.officeLocations?.length >= 1;
        if (this.showOfficeLocations) {
            this.officeLocationDropdownItems = this.mapOfficeLocationsToDropdownItems(this.officeLocations);
            if (this.officeLocations.length === 1) {
                this.onOfficeLocationChange(this.officeLocationDropdownItems);
            }
        }
    }

    public onOfficeLocationChange(selectedOfficeLocation: OrderedSet<DropdownItem>): void {
        this.selectedOfficeLocationDropdownItems = selectedOfficeLocation;
        this.implementProposalOptions.officeLocationId = Number(this.selectedOfficeLocationDropdownItems?.first()?.id);
        this.officeLocationDescription = this.selectedOfficeLocationDropdownItems?.first()?.name;
    }

    public isDAOIntegrationEnabled(): boolean {
        return this.featureFlagService.isDAOIntegrationEnabled();
    }

    public isConfirmationPage(): boolean {
        return this.implementModalService.getCurrentModalState() === ImplementModalState.ConfirmationPage;
    }

    public showFailureMessage(): boolean {
        return this.implementModalService.getValidationErrors().length > 0;
    }

    public onClose(refreshDashboard: boolean = false): void {
        this.implementModalService.resetModalState();
        this.implementModalComponent.hideModalBox();
        if (refreshDashboard) {
            this.implementModalService.refreshDashboardProposals();
            this.implementModalService.resetRefreshSubject();
        }
    }

    public navigateToDAO(): void {
        this.implementModalService.navigateToDAO();
    }

    public getProposalAccounts(): Account[] {
        return this.proposal?.proposalAccounts;
    }

    public hasExistingAccounts(): boolean {
        return this.proposal?.existingAccounts.length > 0;
    }

    public printModal(): void {
        this.isPrinting = true;
        setTimeout(() => {
            this.printExportComponent.beginPrinting();
        });
    }

    public printingComplete(): void {
        this.isPrinting = false;
    }

    public isLoading(): boolean {
        return this.isSubmissionLoading;
    }

    public getPendingAccountId(account: Account): string {
        const pendingAccounts: ImplementedAccountStatus[] = this.implementModalService.getSuccessfulAccountStatuses();
        const matchingAccount: ImplementedAccountStatus = pendingAccounts
            ?.find((pendingAccount: ImplementedAccountStatus) => pendingAccount?.proposalAccountId === account?.id);
        return matchingAccount?.accountOpenId;
    }

    public hasAtLeastOneProposedAccount(): boolean {
        if (this.proposal.proposalAccounts.length === 0) {
            this.selectedValue = PostProposalImplementRedirectActions.Account;
        }
        return this.proposal.proposalAccounts.length > 0;
    }

    public hasExceededMaxErrors(): boolean {
        return this.implementModalService.hasExceededMaxErrors();
    }

    public getPartialFailedAccountsMessage(): string {
        const pendingAccounts: ImplementedAccountStatus[] = this.implementModalService.getSuccessfulAccountStatuses();
        const failedAccountCount: number = pendingAccounts?.filter((account: ImplementedAccountStatus) => !account.accountOpenId)?.length;
        if (failedAccountCount > 0) {
            if (failedAccountCount === 1) {
                return '1 account has failed. You may manually add this account to the proposal account group in Digital Account Open.';
            } else {
                return `${failedAccountCount} accounts have failed. ` +
                    `You may manually add these accounts to the proposal account group in Digital Account Open.`;
            }
        }
        return undefined;
    }

    public getPartialSuccessfulAccountsMessage(): string {
        const pendingAccounts: ImplementedAccountStatus[] = this.implementModalService.getSuccessfulAccountStatuses();
        const successfulAccountCount: number =
            pendingAccounts?.filter((account: ImplementedAccountStatus) => !!account.accountOpenId)?.length;
        if (successfulAccountCount > 0) {
            if (successfulAccountCount === 1) {
                return '1 account was successfully generated.';
            } else {
                return `${successfulAccountCount} accounts were successfully generated.`;
            }
        }
        return undefined;
    }

    public isFullSuccess(): boolean {
        const pendingAccounts: ImplementedAccountStatus[] = this.implementModalService.getSuccessfulAccountStatuses();
        const successfulAccountsLength: number =
            pendingAccounts?.filter((account: ImplementedAccountStatus) => !!account.accountOpenId)?.length;
        return pendingAccounts?.length === successfulAccountsLength;
    }

    public navigateToChecklist(): void {
        this.implementModalService.navigateToWipChecklist(this.proposal.proposalId);
        this.onClose();
    }

    public containsValidationError(): boolean {
        return !this.implementModalService.getValidationErrors()?.some((error: string) =>
            error === ImplementProposalConstants.IMPLEMENT_SERVICE_MAX_ERROR_MESSAGE ||
            error === ImplementProposalConstants.GENERIC_IMPLEMENT_SERVICE_ERROR_MESSAGE);
    }

    private mapOfficeLocationsToDropdownItems(officeLocations: OfficeLocation[]): OrderedSet<DropdownItem> {
        let officeLocationDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
        if (officeLocations && officeLocations.length > 0) {
            officeLocations.forEach((location: OfficeLocation) => {
                const dropdownItem: DropdownItem = new DropdownItem(location.officeLocationId, location.officeName);
                officeLocationDropdownItems = OrderedSet([...officeLocationDropdownItems.toArray(), dropdownItem]);
            });

        }
        return officeLocationDropdownItems;
    }

    private subscribeToLoadingState(): void {
        this.subscriptions.push(
            this.implementModalService.getIsLoadingBehaviorSubject().subscribe((isLoading: boolean) => {
                this.isSubmissionLoading = isLoading;
            })
        );
    }

}

