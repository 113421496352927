/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { CoverPageBaseComponent } from '../cover-page-base';

@Component({
    standalone: true,
    selector: 'sei-car-cover-page-custom',
    templateUrl: './cover-page-custom.component.html',
    styleUrls: ['./cover-page-custom.component.scss'],
    imports: [
        CommonModule,
        DatePipe
    ]

})
export class CoverPageCustomComponent extends CoverPageBaseComponent {
}
