/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WarningModalConstants } from '@CarModels/constants';
import { InvestmentProgramType } from '@CarModels/enums';
import { ExistingAccountData, ExistingAccountOwner } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Account, AccountType, Client, CurrentAccountTypeMapping, DelegationService, Proposal, ProposalAccountService, ProposalService, Strategy } from '@sei/advisor-client-review-proposal-ux';
import { DisplayAmountOptions, DisplayNullOptions, DisplayNumericScaleSuffix, DropdownItem, EditAmountOptions, NumericScale } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { ClientFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/client-factory';
import { InvestmentProgram } from 'projects/advisor-client-review-proposal-ux/src/lib/model/investment-program';

@Component({
    selector: 'sei-car-existing-accounts-card',
    templateUrl: './existing-accounts-card.component.html',
    styleUrls: ['./existing-accounts-card.component.scss']
})
export class ExistingAccountsCardComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public set account(value: ExistingAccountData) {
        this._account = value;
    }

    public get account() {
        return this._account;
    }

    @Input()
    public proposal: Proposal;

    @Input()
    set accountTypes(value: AccountType[]) {
        this._accountTypes = value;
        if (!this.currentAccountToProposedAccountTypeList) {
            this.currentAccountToProposedAccountTypeList = Object.keys(CurrentAccountTypeMapping);
        }
        this.setAccountTypeDropdownItems();
        if (this.accountTypeId) {
            const accountType: AccountType = this.accountTypes.find((type: AccountType) => type.accountTypeId === this.accountTypeId);
            this.investmentPrograms = accountType.investmentPrograms;
            this.mapInvestmentProgramDropdownItems();
        }
    }

    get accountTypes(): AccountType[] {
        return this._accountTypes;
    }

    public displayNumericScaleSuffix: DisplayNumericScaleSuffix = {
        scale: NumericScale.NULL,
        displayDecimals: 2
    };
    public roundFractionalNumber: boolean = false;
    public amountOptions: DisplayAmountOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        roundFractionalAmount: false
    };
    public newFundsAmountOptions: EditAmountOptions;

    public primaryAccountOwnerClient: Client;
    public selectedClientList: Client[] = [];
    public proposalClientList: Client[] = [];
    public accountTypeId: number;
    public amountErrors: string[];
    public newFundsAmount: number;
    public isAmountValid: boolean;
    public accountTypesSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedAccountTypeSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public investmentProgramDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedInvestmentProgramSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedInvestmentProgramBackupSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedInvestmentProgramType: string;
    public showModal: boolean = false;
    public showResetModal: boolean = false;
    public modalMessage: string = WarningModalConstants.MODAL_MESSAGE_INVESTMENT_PROGRAM;
    public accountResetModalMessage: string = WarningModalConstants.MODAL_MESSAGE_ACCOUNT_CARD_RESET;
    public investmentProgramDropdownDisabled: boolean = false;
    public existingAccountTypeId: number;

    private investmentPrograms: InvestmentProgram[] = [];
    private _accountTypes: AccountType[];
    private currentAccountToProposedAccountTypeList: string[];
    private _account: ExistingAccountData;

    constructor(private delegationService: DelegationService,
        private existingAccountsService: ExistingAccountsService,
        private router: Router,
        private proposalService: ProposalService,
        private proposalAccountService: ProposalAccountService) {
        super('ExistingAccountsCardComponent');
    }

    ngOnInit(): void {
        this.setNewFundsAmountOptions();
        this.setClientLists();
        this.validateProposal();
    }

    public onModalContinue(): void {
        this.investmentProgramChange();
        this.showModal = false;
    }

    public onModalCancel(): void {
        this.selectedInvestmentProgramSet = _.cloneDeep(this.selectedInvestmentProgramBackupSet);
        this.selectedInvestmentProgramBackupSet = undefined;
        this.showModal = false;
    }

    public accountResetClicked(): void {
        this.showResetModal = true;
    }

    public onResetModalCancel(): void {
        this.showResetModal = false;
    }

    public onResetModalContinue(): void {
        const currentAccountInProposalIndex: number =
            this.proposal.scenarios[0].accounts.findIndex((accountItem: Account) =>
                accountItem.currentAccountId === this.account.accountId);
        if (currentAccountInProposalIndex >= 0) {
            const existingAccounts: ExistingAccountData[] =
                this.existingAccountsService
                    .convertExistingAccountsMapToArray(this.proposalService.getExistingAccountsPickListFromCurrentProposal());
            const snapShotAccountData: ExistingAccountData = existingAccounts
                .find((accountData: ExistingAccountData) => accountData?.accountNumber === this.account?.accountNumber);
            const addedAccountIndex: number = this.proposal.scenarios[0].accounts.length;
            // We always want the snapshot investment program id when resetting the account
            const investmentProgramId: number = snapShotAccountData.investmentProgramId;
            const account: Account = this.existingAccountsService
                .createAccountFromCurrentAccount(this.proposal,
                    snapShotAccountData, addedAccountIndex, investmentProgramId);
            this.proposal.scenarios[0].accounts.splice(currentAccountInProposalIndex, 1, account);
            this.proposalService.changedProposal(_.cloneDeep(this.proposal));
            this.existingAccountsService.showMappingModalForForceRefresh.next(true);
        }
        this.showResetModal = false;
    }

    public onModifyInvestmentsClicked(): void {
        const currentAccountInProposal: Account =
            this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
                accountItem.currentAccountId === this.account.accountId);
        if (!currentAccountInProposal) {
            const addedAccountIndex: number = this.proposal.scenarios[0].accounts.length;
            const investmentProgramId: number = Number(this.selectedInvestmentProgramSet?.first()?.id);
            const account: Account = this.existingAccountsService
                .createAccountFromCurrentAccount(this.proposal, this.account, addedAccountIndex, investmentProgramId);
            this.proposal.scenarios[0].accounts.push(account);
            this.router.navigate(
                ['Proposal', 'WIP', this.proposal.id, 'Scenarios', this.proposal.scenarios[0].id, 'Accounts', addedAccountIndex]);
        } else {
            currentAccountInProposal.sweepModelElection = {
                isFdic: this.account.fdic,
                currencySweepModelAllocDescription: this.account.sweepModelDescription,
                platformSweepModelId: this.account.sweepModelId
            };
            currentAccountInProposal.platformFeeCapDetails = this.account.platformFeeCapDetails;
            this.router.navigate(
                ['Proposal', 'WIP', this.proposal.id, 'Scenarios', this.proposal.scenarios[0].id, 'Accounts', currentAccountInProposal.id]);
        }
    }

    public amountDifferenceAbsoluteValue(): number {
        return Math.abs(this.calculateAmountDifference());
    }

    public calculateAmountDifference(): number {
        const currentAccountInProposal: Account =
            this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
                accountItem.currentAccountId === this.account.accountId);
        if (currentAccountInProposal) {
            let accountBalance: number = 0;
            currentAccountInProposal?.strategies?.forEach((strategy: Strategy) => {
                accountBalance += strategy.amount;
            });
            accountBalance += this.proposalAccountService.calculateNonModelInvestmentTotal(currentAccountInProposal?.portfolios);
            return accountBalance - this.account.actualCurrentMarketValue;
        } else {
            return 0;
        }
    }

    public onInvestmentProgramChange(selectedInvestmentPrograms: OrderedSet<DropdownItem>): void {
        this.selectedInvestmentProgramBackupSet = _.cloneDeep(this.selectedInvestmentProgramSet);
        if (this.selectedInvestmentProgramSet?.first()) {
            this.selectedInvestmentProgramSet = selectedInvestmentPrograms;
            this.showWarningModal();
        } else {
            this.selectedInvestmentProgramSet = selectedInvestmentPrograms;
            this.investmentProgramChange();
        }
        this.validateProposal();
    }

    public onAccountTypeDropdownChange(accountTypeDropdownSet: OrderedSet<DropdownItem>): void {
        if (accountTypeDropdownSet.size > 0) {
            this.selectedInvestmentProgramBackupSet = _.cloneDeep(this.selectedInvestmentProgramSet);
            this.selectedAccountTypeSet = accountTypeDropdownSet;
            this.account.accountType = accountTypeDropdownSet.first().name;
            this.accountTypeId = Number(accountTypeDropdownSet.first().id);
            this.mapInvestmentProgramDropdownItems();
        }
        this.validateProposal();
    }

    public verifyExistingAccountCardCompleted(): boolean {
        if (this.accountTypeId && this.account.investmentProgramId) {
            return true;
        } else {
            return false;
        }
    }

    private investmentProgramChange(): void {
        const currentAccountInProposal: Account =
            this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
                accountItem.currentAccountId === this.account.accountId);
        if (currentAccountInProposal) {
            currentAccountInProposal.strategies = [];
            currentAccountInProposal.investmentProgramId = Number(this.selectedInvestmentProgramSet?.first()?.id);
            this.account.investmentProgramId = Number(this.selectedInvestmentProgramSet?.first()?.id);
            this.proposalService.changedProposal(this.proposal);
        }
    }

    private mapInvestmentProgramDropdownItems(): void {
        this.investmentProgramDropdownItems = OrderedSet([]);
        if (this.investmentPrograms) {
            this.investmentPrograms.sort((a: InvestmentProgram, b: InvestmentProgram) => a.sortOrder - b.sortOrder);
            this.investmentPrograms.forEach((investmentProgram: InvestmentProgram) => {
                const dropdownItem: DropdownItem =
                    new DropdownItem(investmentProgram.investmentProgramId, investmentProgram.investmentProgramDescription);
                this.investmentProgramDropdownItems = OrderedSet([...this.investmentProgramDropdownItems.toArray(), dropdownItem]);
            });
            const currentAccountInProposal: Account =
                this.proposal?.scenarios[0]?.accounts?.find((accountItem: Account) =>
                    accountItem?.currentAccountId === this.account?.accountId);
            const investmentProgramId: number = currentAccountInProposal?.investmentProgramId || this.account?.investmentProgramId;
            if (investmentProgramId) {
                const selectedInvestmentProgram: InvestmentProgram =
                    this.investmentPrograms.find((investmentProgram: InvestmentProgram) =>
                        investmentProgram.investmentProgramId === investmentProgramId);
                this.selectedInvestmentProgramSet = OrderedSet([new DropdownItem(selectedInvestmentProgram.investmentProgramId,
                    selectedInvestmentProgram.investmentProgramDescription)]);
                const isInvestmentProgramEditable: boolean = investmentProgramId === InvestmentProgramType.MutualFunds ||
                    investmentProgramId === InvestmentProgramType.ManagedAccounts;
                const filteredDropdownItems: OrderedSet<DropdownItem> =
                    OrderedSet(this.investmentProgramDropdownItems.toArray().filter((dropdownItem: DropdownItem) =>
                        dropdownItem.id === InvestmentProgramType.MutualFunds ||
                        dropdownItem.id === InvestmentProgramType.ManagedAccounts
                    ));
                if (isInvestmentProgramEditable) {
                    this.investmentProgramDropdownItems = _.cloneDeep(filteredDropdownItems);
                    this.investmentProgramDropdownDisabled = false;
                } else {
                    this.investmentProgramDropdownItems = _.cloneDeep(this.selectedInvestmentProgramSet);
                    this.investmentProgramDropdownDisabled = true;
                }
            }
        }
    }

    private setAccountTypeDropdownItems(): void {
        this.accountTypesSet = OrderedSet([]);
        if (this.account.accountType) {
            let selectedDropdownItem: DropdownItem;
            if (this.currentAccountToProposedAccountTypeList.includes(this.account.accountType)) {
                let selectedAccountType: AccountType;
                const enumItem: string[] = CurrentAccountTypeMapping[this.account.accountType].split(',');
                if (enumItem.length > 1) {
                    enumItem.forEach((id) => {
                        selectedAccountType = this.accountTypes.find((account: AccountType) => account.accountTypeId.toString() === id);
                        this.investmentPrograms = selectedAccountType?.investmentPrograms;
                        this.accountTypesSet = OrderedSet([...this.accountTypesSet.toArray(),
                        new DropdownItem(selectedAccountType.accountTypeId, selectedAccountType.accountTypeDescription)]);
                    });
                    selectedAccountType = undefined;
                } else {
                    selectedAccountType = this.accountTypes.find((account: AccountType) =>
                        account.accountTypeId.toString() === enumItem[0]);
                    this.investmentPrograms = selectedAccountType?.investmentPrograms;
                    this.account.accountType = selectedAccountType.accountTypeDescription;
                    selectedDropdownItem = new DropdownItem(selectedAccountType.accountTypeId, selectedAccountType.accountTypeDescription);
                }
            } else {
                const selectedAccountType: AccountType = this.accountTypes
                    .find((account: AccountType) => account.accountTypeDescription === this.account.accountType);
                selectedDropdownItem = new DropdownItem(selectedAccountType.accountTypeId, selectedAccountType.accountTypeDescription);
                this.investmentPrograms = selectedAccountType?.investmentPrograms;
            }

            if (selectedDropdownItem) {
                this.accountTypesSet = OrderedSet([...this.accountTypesSet.toArray(), selectedDropdownItem]);
                this.accountTypeId = Number(selectedDropdownItem.id);
                this.selectedAccountTypeSet = OrderedSet([selectedDropdownItem]);
            }

            this.mapInvestmentProgramDropdownItems();
        }
    }

    private showWarningModal(): void {
        this.showModal = true;
    }

    private setClientLists(): void {
        this.selectedClientList = [];
        const primaryAccountOwner: ExistingAccountOwner =
            this.account.accountOwnerList.find((owner: ExistingAccountOwner) => owner.primary);
        const primaryOwnerClient: Client = this.proposal.clients
            .find((client: Client) => Number(client.entityId) === Number(primaryAccountOwner.endClientId));
        this.account.accountOwnerList.forEach((owner: ExistingAccountOwner) => {
            const ownerClient: Client = new ClientFactory().createAccountOwner(owner.endClientId,
                `${owner.lastName}, ${owner.firstName}`,
                owner.firstName,
                owner.middleName,
                owner.lastName);
            ownerClient.isNewClient = false;
            ownerClient.contactType = primaryOwnerClient.contactType;
            ownerClient.emailAddress = owner.email;
            ownerClient.phoneNumber = owner.phone;
            if (primaryOwnerClient.contactType) {
                this.existingAccountTypeId = primaryOwnerClient.contactType.id;
                if (this.existingAccountTypeId === 2) {
                    ownerClient.organizationName = ownerClient.lastName;
                }
            }
            owner.primary ? this.selectedClientList.unshift(ownerClient) :
                this.selectedClientList = [...this.selectedClientList, ownerClient];
            this.proposalClientList = _.cloneDeep(this.selectedClientList);
        });
    }

    private setNewFundsAmountOptions(): void {
        this.newFundsAmountOptions = {
            baseCurrency: 'USD',
            roundFractionalAmount: false,
            displayCurrencySymbol: true,
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };
    }

    private validateProposal(): void {
        this.proposalService.validateProposal(this.proposal, this.proposal?.scenarios[0]?.id);
    }

    public removeSelectedExistingAccount(): void {
        this.existingAccountsService.removeExistingAccountFromPickList(this.account);
    }
}
