/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TooltipConstants } from '@CarModels/constants';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription } from 'rxjs';
import { CommandText, ProposalStatusId } from '../../model/enums';
import { Proposal } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { ProposalAccountService } from '../../service/proposal-account.service';
import { ProposalService } from '../../service/proposal.service';
import { GenericErrorService } from '../../service/system/generic-error.service';

@Component({
    selector: 'car-wip-checklist-footer',
    templateUrl: './wip-checklist-footer.component.html'
})
export class WipChecklistFooterComponent extends ParentComponentSubscriptionManager implements OnInit, OnChanges {

    @Input() set isForceRefreshRequiredForCurrentAccounts(value: boolean) {
        this._isForceRefreshRequiredForCurrentAccounts = value;
        this.initWipChecklistFooter();
    }

    get isForceRefreshRequiredForCurrentAccounts(): boolean {
        return this._isForceRefreshRequiredForCurrentAccounts;
    }

    @Input()
    enablePresentationButton: boolean = false;

    @Input()
    enableImplementButton: boolean = false;

    @Input()
    public isReadUser: boolean;

    @Input() set isChecklistLocked(value: boolean) {
        this._isChecklistLocked = value;
        this.initWipChecklistFooter();
    }

    get isChecklistLocked(): boolean {
        return this._isChecklistLocked;
    }

    public implementScenario: number;
    public showImplementPopover: boolean = false;
    public scenarios: Array<{ value: number; label: string }> = [];
    public isStatusValid: boolean = false;
    public isChartPOCEnabled = false;
    public isChecklistRedesignEnabled = false;
    public proposal: Proposal;
    private _isForceRefreshRequiredForCurrentAccounts: boolean = false;
    private _isChecklistLocked: boolean = false;

    constructor(
        private router: Router,
        private proposalService: ProposalService,
        private proposalAccountService: ProposalAccountService,
        private delegationService: DelegationService,
        private genericErrorService: GenericErrorService,
        private featureFlagService: FeatureFlagService
    ) {
        super('WipChecklistFooterComponent');
    }

    public ngOnInit(): void {
        const proposalSubscription: Subscription = this.proposalService.currentProposal.subscribe((proposal: Proposal) => {
            if (proposal) {
                this.proposal = proposal;
                this.initWipChecklistFooter();
            }
        });
        this.subscriptions.push(proposalSubscription);

        const delegationServiceSubscription: Subscription = this.delegationService.refresh()
            .subscribe(
                (data: string) => {
                    switch (data) {
                        case CommandText.UpdateWipCheckList: {
                            const minimallyComplete = this.proposalAccountService.verifyProposal(this.proposal);
                            this.enablePresentationButton = minimallyComplete;
                            this.enableImplementButton = minimallyComplete;
                            break;
                        }
                        case CommandText.DisableWipImplementProposalButton:
                            this.enableImplementButton = false;
                            break;
                        case CommandText.EnableWipImplementProposalButton:
                            this.enableImplementButton = true;
                            break;
                        default:
                            break;
                    }
                });
        this.subscriptions.push(delegationServiceSubscription);
        this.isChartPOCEnabled = this.featureFlagService.isChartPOCEnabled();
        this.isChecklistRedesignEnabled = this.featureFlagService.isChecklistRedesignEnabled();

    }

    private initWipChecklistFooter(): void {
        this.scenarios = [];
        if (this.proposal) {
            if (this.isChecklistLocked) {
                this.enablePresentationButton = false;
                this.enableImplementButton = false;
            } else {
                this.verifyMinimallyComplete();
            }

            this.proposal.scenarios.forEach((scenario) => {
                this.scenarios.push({
                    value: scenario.id,
                    label: scenario.name
                });
            });

            this.isStatusValid =
                this.proposal.statusId !== ProposalStatusId.Implemented && this.proposal.statusId !== ProposalStatusId.Archived;
        }
    }

    private verifyMinimallyComplete() {
        const minimallyComplete = this.proposalAccountService.verifyProposal(this.proposal);
        this.enablePresentationButton = minimallyComplete;
        this.enableImplementButton = minimallyComplete;
    }

    public ngOnChanges(): void {
        this.initWipChecklistFooter();
    }

    public onSaveExitClick() {
        // Save and exist to dashboard.
        this.proposalService.updateProposalWipCheckList(this.proposal.wipCheckList)
            .subscribe(
                () => {
                    // Reset current proposal
                    this.proposalService.changedProposal(undefined);
                },
                (error) => this.genericErrorService.setGenericError(error)
            );
        this.router.navigate(['Proposal/Dashboard/']);
    }

    public onPresentationClick() {
        this.router.navigate(['Proposal/Presentation/Settings/', this.proposal.id]);
    }

    public onImplementToggleClick() {
        if (!this.isReadUser) {
            this.delegationService.publish(CommandText.DisableWipImplementProposalButton);
            this.delegationService.publish(CommandText.ImplementProposal);
        }
    }

    public onExitClick() {
        this.router.navigate(['Proposal/Dashboard/']);
    }

    public getLockedTooltip(): string {
        return this.isForceRefreshRequiredForCurrentAccounts ? TooltipConstants.PROPOSAL_REQUIRES_EXISTING_ACCOUNT_FORCE_REFRESH :
            TooltipConstants.PROPOSAL_GENERATION_LOCKED_MESSAGE;
    }

    public onChartPOCClick() {
        this.router.navigate(['Proposal/Presentation/POC/TreeMapChart/' + this.proposal.id]);
    }

    public onChecklistRedesignClick() {
        this.router.navigate(['Proposal/Presentation/' + this.proposal.id]);
    }

    public getStrategiesFundedTooltip(): string {
        return this.proposalService.isEveryStrategyInProposalFunded() ? '' : 'Model investments must have an amount greater than $0. '
            + 'In order to proceed, ensure all model investments in this proposal have an amount greater than $0.';
    }
}
