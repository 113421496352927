/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { GlobalService } from '@CarServices/system/global.service';
import { UserProfileService } from '@sei/advisor-client-review-proposal-ux';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    constructor(private carGlobal: GlobalService,
        private userProfileService: UserProfileService
    ) { }

    public isDAOIntegrationEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.daoIntegrationEnabled;
    }

    public checkExistingAccountsSectionEnabled(): boolean {
        const isAllowableFirm: boolean = this.checkTestFirm();
        return this.carGlobal?.configService?.environment?.existingAccountsFeature || isAllowableFirm;
    }

    public isManagementFeeBreakdownEnabled(): boolean {
        const flag = this.carGlobal?.configService?.environment?.managementFeeBreakdownEnabled;
        return flag === undefined || flag === true;
    }

    public isPlaywrightPdfViewerEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.playwrightPdfViewerEnabled;
    }

    public isOverrideSellsOnlyRuleFeatureEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.overrideSellsOnlyRuleFeatureEnabled;
    }

    public isSubstitutionsFeatureFlagEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.substitutionsEnabled;
    }

    public isChartPOCEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.chartPOCEnabled;
    }

    public isChecklistRedesignEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.checklistRedesignEnabled;
    }

    public isEnableThirdPartyMoneyMarket(): boolean {
        return this.carGlobal?.configService?.environment?.enableThirdPartyMoneyMarket;
    }

    public isInvestorPlatformFeeEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.isInvestorPlatformFeeEnabled ?? true;
    }

    public isDisableRefreshBannerDelay(): boolean {
        return this.carGlobal?.configService?.environment?.disableRefreshBannerDelay;
    }

    private checkTestFirm(): boolean {
        const testFirmIds: number[] = this.carGlobal?.configService?.environment?.testFirmIds;
        const isTestFirm: boolean = testFirmIds?.some((firmId: number) =>
            firmId === Number(this.userProfileService?.getCurrentUserProfile?.firm?.swpFirmId) ||
            firmId === Number(this.userProfileService?.firm?.swpFirmId));
        return isTestFirm;
    }

    public isHistoricalAssetPriceFeatureEnabled(): boolean {
        return this.carGlobal?.configService?.environment?.isHistoricalAssetPriceFeatureEnabled;
    }
}
