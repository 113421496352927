/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountData } from '@CarModels/existing-accounts';
import { Component, Input } from '@angular/core';
import { Proposal, Scenario } from '@sei/advisor-client-review-proposal-ux';
import { SubpageheaderModel } from '@sei/common-swp-components-lib-ux';

@Component({
    selector: 'sei-car-existing-accounts-section',
    templateUrl: './existing-accounts-section.component.html',
    styleUrls: ['./existing-accounts-section.component.scss']
})
export class ExistingAccountsSectionComponent {

    @Input()
    existingAccounts: ExistingAccountData[];
    @Input()
    proposal: Proposal;
    @Input()
    scenario: Scenario;
    public sectionHeaderProperties: SubpageheaderModel = {
        title: 'Current Accounts',
        switcherAvailable: false
    };
}
