/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { PropertyService } from '@CarServices/system/property.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ParentComponentSubscriptionManager, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { FirmInfoSlice, IASCommonAppStore } from '@sei/ias-applications-lib-ux';
import { parseDigits } from 'libphonenumber-js';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ModalWindowComponent } from '@sei/common-components-lib-ux';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { WarningModalConstants } from '@CarModels/constants';
import { EntityType } from '@CarModels/enums';
import { CurrentAdvisor } from '../../model/advisor-selected';
import { EntitySuffix } from '../../model/contact';
import { CommandText, ContactTypes, IndividualOrganization, Proposals, UserLevelEntitlement, WipChecklistLabels } from '../../model/enums';
import { ClientFactory } from '../../model/factory/client-factory';
import { FeesFactory } from '../../model/factory/fees-factory';
import { Account, AccountOwner, AccountType, Advisor, Client, Fees, Proposal, Scenario, WipCheckList } from '../../model/proposal';
import { UserProfile } from '../../model/user-profile';
import { DelegationService } from '../../service/delegation.service';
import { ProposalService } from '../../service/proposal.service';
import { GenericErrorService } from '../../service/system/generic-error.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'car-proposal-scenario',
    templateUrl: './proposal-scenario.component.html',
    styleUrls: ['./proposal-scenario.component.scss']
})
export class ProposalScenarioComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input() public proposal: Proposal;
    @Input() public scenario: Scenario;
    @Input() public accountTypeData: AccountType[];
    @Input() public suffix: EntitySuffix[];
    @ViewChild('existingAccountsResetModal')
    existingAccountsResetModal: ModalWindowComponent;

    public get proposalNewId(): Proposals {
        return Proposals.NewId;
    }

    public showEditMode: boolean = false;
    public hideEditButton: boolean = false;
    public showProposedAccounts: boolean;
    public scenarioAccountBalance: number = 0;
    public scenarioForm: UntypedFormGroup;
    public scenarioFormSubscription: Subscription;
    public scenarioBackup: Scenario;
    public platformFeeBasisPoint: number;
    public firmId: number = 1; // For only Elastic search passing firm id as 1 for non-instance users
    public userProfile: UserProfile;
    public existingAccountsResetModalTitle: string = 'Account Reset';

    public proposedAccountHeaderProperties: SubpageheaderModel;
    public headingLevel: number = 2;
    public accountAdvisorList: Advisor[] = [];
    public allOwnersValid: boolean = false;
    public showAllAccountsResetModal: boolean = false;
    public existingAccountsResetModalBodyMessage: string = WarningModalConstants.MODAL_MESSAGE_CLEAR_ALL_EXISTING_ACCOUNT_MODIFICATIONS;
    private proposalBackup: Proposal;
    private existingAccountsBackup: Map<number, ExistingAccountData[]> = new Map();
    private isCurrentAccountsFeatureEnabled: boolean = false;
    private didComponentInitiateCurrentAccountRefresh: boolean = false;

    constructor(
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private genericErrorService: GenericErrorService,
        private readonly appStore: Store<IASCommonAppStore>,
        private router: Router,
        private readonly propService: PropertyService,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService,
        private userProfileService: UserProfileService,
        private existingAccountsService: ExistingAccountsService,
        private featureFlagService: FeatureFlagService
    ) {
        super('ProposalScenarioComponent');
        this.proposedAccountHeaderProperties = {
            title: 'Search Owners',
            switcherAvailable: false
        };
    }

    ngOnInit() {
        this.initScenario();

        // Note: process actions
        this.subscriptions.push(
            this.delegationService.refresh().subscribe((action: string) => {
                this.processAction(action);
            })
        );
        this.appStore.select((slice: IASCommonAppStore): FirmInfoSlice => slice.firmInfoSlice).pipe(
            take(1)
        ).subscribe((slice: FirmInfoSlice) => {
            if (slice?.values?.PlatformFeeBasisPoints) {
                this.platformFeeBasisPoint = +slice.values.PlatformFeeBasisPoints;
            }
        });
        this.delegationService.publish(CommandText.ProposeScenario);
        this.userProfile = this.userProfileService.getCurrentUserProfile;
        const isInstanceUser: boolean = this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;
        if (isInstanceUser) {
            this.firmId = this.userProfile.firm.swpFirmId;
        }
        this.isCurrentAccountsFeatureEnabled = this.featureFlagService.checkExistingAccountsSectionEnabled();
    }

    public initScenario() {
        this.scenarioBackup = _.cloneDeep(this.scenario);
        this.proposalBackup = _.cloneDeep(this.proposal);
        if (this.proposal.clients.length === 0) {
            this.onAddOwnerClick();
        }
    }

    private processAction(action: string) {
        switch (action) {
            case CommandText.CreateProposal:
                this.createProposal();
                break;
            case CommandText.ModifyProposalScenario:
                this.displayWarningModalIfNecessary();
                break;
            case CommandText.CancelRightHandDialog:
                this.restoreScenario();
                break;
            case CommandText.ExistingAccountsRefreshCompleted:
                if (this.didComponentInitiateCurrentAccountRefresh) {
                    this.modifyProposalScenario(true);
                }
                break;
            default:
                break;
        }
    }

    private validateProposal(): boolean {
        this.proposal.clients.forEach((client) => {
            if (client.contactType.id === IndividualOrganization.Individual) {
                client.organizationName = '';
            } else {
                client.firstName = '';
                client.middleInitial = '';
                client.entitySuffix = { suffixId: 0, suffixName: '' };
                client.lastName = client.organizationName || '';
            }
        });

        const proposalValid: boolean = this?.proposal?.advisorId && this?.proposal?.clients?.length > 0 && this.isAllOwnersValid();

        return proposalValid;
    }

    private setSaveButtonStatus(isReadyToSave: boolean): void {
        if (isReadyToSave) {
            this.delegationService.publish(CommandText.EnableScenario);
            this.delegationService.publish(CommandText.EnableCreateProposal);
            this.delegationService.publish(CommandText.EnableSaveAndExitButton);

        } else {
            this.delegationService.publish(CommandText.DisableScenario);
            this.delegationService.publish(CommandText.DisableCreateProposal);
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        }
    }

    public onAddOwnerClick(): void {
        const client: Client = new ClientFactory().createAccountOwner(0);
        this.proposal.clients.push(client);
        this.validateSaveButton();
    }

    public createProposal(): void {
        if (this.validateProposal()) {
            const client: Client = this.proposal?.clients[0];
            const clientName = `${client?.firstName} ${client?.lastName}`;
            const trimmedName: string = clientName.trim();
            this.proposal.name = trimmedName;
            this.proposal.scenarios[0].name = trimmedName;
            const proposalToSend: Proposal = _.cloneDeep(this.proposal);
            this.formatPhoneNumber(proposalToSend);
            this.spinnerService.start();
            this.proposalService.proposalUpsert(proposalToSend).subscribe({
                next: (response: Proposal) => {
                    this.router.navigate(
                        ['../Proposal/WIP/', response.id],
                        { relativeTo: this.route }
                    );
                },
                error: (error) => {
                    this.genericErrorService.setGenericError({
                        code: '',
                        description: 'Invalid Entry'
                    });
                    this.spinnerService.stop();
                },
                complete: () => {
                    this.spinnerService.stop();
                }
            });
        }
    }

    public modifyProposalScenario(existingAccountsRefresh: boolean = false): void {
        this.spinnerService.start();
        if (this.validateProposal()) {
            this.existingAccountsService.resetTemporaryPortfolioIdsForNewCurrentAccounts(this.proposal);
            let newFees: Fees;
            this.proposal.scenarios.forEach((scenario) => {
                scenario.accounts.forEach((account) => {
                    if (this.propService.notExists(() => (account.fees))) {
                        newFees = new FeesFactory().createFees(0, 0, 0, 0, 0, 0, 0, 0);
                        account.fees = newFees;
                    }
                    account.fees.platformFeeBasisPoints = this.platformFeeBasisPoint;
                });
            });
            const firstOwner: Client = this.proposal.clients[0];
            const ownerName: string = `${firstOwner.firstName} ${firstOwner.lastName}`;
            const trimmedName: string = ownerName.trim();
            this.proposal.name = trimmedName;
            this.proposalService.editProposalName(this.proposal.id, trimmedName).subscribe((name: string) => {
                this.proposal.name = name;
            });
            this.proposal.scenarios[0].name = trimmedName;
            const proposalToSend: Proposal = _.cloneDeep(this.proposal);
            proposalToSend.clients.forEach((client: Client) => {
                client.isNewClient = false;
            });
            this.formatPhoneNumber(proposalToSend);
            this.proposalService.updateProposalScenario(proposalToSend, this.scenario.id).subscribe((responseProposal: Proposal) => {

                const scenarioSectionIndex: number = responseProposal.wipCheckList.findIndex((wipSection: WipCheckList) => {
                    return (
                        wipSection.sectionType ===
                        WipChecklistLabels.ProposalScenario
                    );
                });

                responseProposal.wipCheckList[scenarioSectionIndex].name = this.scenario.name;
                responseProposal.wipCheckList[scenarioSectionIndex].balance = this.scenario.accountBalance;

                // Note need to retrieve wipChecklist with unique identifier 'Scenarios/1005/Accounts/1302
                responseProposal.scenarios.forEach((scenario) => {
                    const scenarioRoute: string = `Scenarios/${scenario.id}`;
                    scenario.accounts.forEach((account) => {
                        const accountRoute: string = `${scenarioRoute}/Accounts/${account.id}`;
                        const wipCheckListIndexToUpdate: number = responseProposal.wipCheckList[scenarioSectionIndex]
                            .subCheckList.findIndex((wipSection: WipCheckList) => {
                                return (wipSection.route === accountRoute);
                            });

                        if (wipCheckListIndexToUpdate > -1) {
                            responseProposal.wipCheckList[scenarioSectionIndex]
                                .subCheckList[wipCheckListIndexToUpdate].balance = account.balance;
                        }
                    });
                });
                const wipChecklistIndex: number = responseProposal.wipCheckList.findIndex(
                    (wipChecklist: WipCheckList) => wipChecklist.sectionType === WipChecklistLabels.ProposalScenario);
                responseProposal.wipCheckList[wipChecklistIndex].name = trimmedName;
                this.proposalService.updateProposalWipCheckList(responseProposal.wipCheckList).subscribe((response) => {
                    this.proposal.wipCheckList = responseProposal.wipCheckList;
                    this.proposalService.changedProposal(responseProposal);
                    this.delegationService.publish(CommandText.UpdateWipCheckList);
                    this.spinnerService.stop();
                    this.existingAccountsService.removeUnusedClientAccounts(responseProposal.clients);
                    if (existingAccountsRefresh) {
                        this.existingAccountsService.showMappingModalForForceRefresh.next(false);
                        this.didComponentInitiateCurrentAccountRefresh = false;
                    }
                    this.router.navigate(['../Proposal/WIP/', this.route.snapshot.parent.params.proposalId]);
                });
            });

        }
    }

    public validateSaveButton(): void {
        const isReadyToSave = this.validateProposal();
        this.setSaveButtonStatus(isReadyToSave);
    }

    public restoreScenario(): void {
        this.existingAccountsService.removeUnusedClientAccounts(this.proposal.clients);
        this.existingAccountsService.restoreExistingAccountsForClients(this.existingAccountsBackup);
        const scenarioIndex = this.proposal.scenarios.findIndex((scenario) => scenario.id === this.scenario.id);
        if (
            this.proposal &&
            this.proposal.scenarios &&
            this.proposal.scenarios[scenarioIndex]
        ) {
            this.proposal.scenarios[scenarioIndex] = this.scenarioBackup;
            this.proposalService.changedProposal(this.proposalBackup);
        }
    }

    public onAdvisorChange(currentAdvisors: CurrentAdvisor[]) {
        this.proposal.advisorId = currentAdvisors[0]?.advisorSelected.entityId;
        const advisorList: Advisor[] = this.currentAdvisorsToAdvisors(currentAdvisors);
        this.proposal.advisors = advisorList;
        if (this.proposal.scenarios[0].accounts) {
            this.proposal?.scenarios[0]?.accounts.forEach((account: Account): void => {
                account.advisors = advisorList;
            });
        }
        this.validateSaveButton();
    }

    public currentAdvisorsToAdvisors(currentAdvisors: CurrentAdvisor[]): Advisor[] {
        const advisors: Advisor[] = [];
        currentAdvisors.forEach((advisor: CurrentAdvisor): void => {
            advisors.push(advisor.advisorSelected);
        });
        return advisors;
    }

    private isAllOwnersValid(): boolean {
        const valid: boolean = this.proposal.clients.every((client: Client): boolean => {
            return client.contactType.id === ContactTypes.Individual ?
                !!client?.firstName && !!client?.lastName && (client.id > 0 || client.entityId ? true : !!client?.isOptionalFieldsValid) :
                !!client?.organizationName && (client.id > 0 || client.entityId ? true : !!client?.isOptionalFieldsValid);
        });
        this.allOwnersValid = valid;
        return this.allOwnersValid;
    }

    public deleteOwner(index: number, clientToDelete: Client): void {
        const scenarioIndex = this.proposal?.scenarios?.findIndex((scenario: Scenario) => scenario?.id === this.scenario?.id);
        this.proposal?.clients?.splice(index, 1);
        this.proposalService.changedProposal(this.proposal);
        if (this.proposal?.clients?.length === 0) {
            this.onAddOwnerClick();
        }

        if (this.proposal?.scenarios[scenarioIndex]?.accounts?.length > 0) {
            // Make copy of accounts since we might remove elements from the array
            const proposalAccountsCopy: Account[] = _.cloneDeep(this.proposal?.scenarios[scenarioIndex]?.accounts);
            proposalAccountsCopy?.forEach((proposalAccount: Account) => {
                // Make copy of account owners since we might remove elements from the array
                const accountOwnersCopy: AccountOwner[] = _.cloneDeep(proposalAccount?.owners);
                accountOwnersCopy?.forEach((accountOwner: AccountOwner) => {
                    if (accountOwner?.contactId === clientToDelete?.id && proposalAccount?.owners?.length === 1) {
                        this.proposal.scenarios[scenarioIndex].accounts =
                            this.proposal?.scenarios[scenarioIndex]?.accounts?.filter((accountToDelete: Account) =>
                                accountToDelete?.id !== proposalAccount?.id);
                    } else if (accountOwner?.contactId === clientToDelete?.id && proposalAccount?.owners?.length > 1) {
                        const originalAccount = this.proposal?.scenarios[scenarioIndex]?.accounts?.find((account) => {
                            return account.id === proposalAccount.id;
                        });
                        originalAccount.owners =
                            proposalAccount?.owners?.filter((ownerToDelete: AccountOwner) => ownerToDelete?.id !== accountOwner?.id);
                    }
                });
            });
        }
        const isOwnerAnExistingClient: boolean = clientToDelete.entityType === EntityType.Client;
        const areExistingAccountsPresentInAccountsMapOnProposal: boolean =
            this.proposalService.getExistingAccountsPickListFromCurrentProposal()?.size > 0;
        if (this.isCurrentAccountsFeatureEnabled && isOwnerAnExistingClient && areExistingAccountsPresentInAccountsMapOnProposal) {
            const deletedAccountsForClient: ExistingAccountData[] =
            this.existingAccountsService.removeExistingAccountsFromMapByClientId(clientToDelete.id);
            this.existingAccountsBackup.set(clientToDelete.id, deletedAccountsForClient);
        }
        this.validateSaveButton();
    }

    public onClientAdded(client: Client, index: number): void {
        this.proposal.clients[index] = client;
        this.validateSaveButton();
    }

    private formatPhoneNumber(proposal: Proposal): void {
        proposal?.clients.forEach((client: Client) => {
            if (client.phoneNumber) {
                client.phoneNumber = parseDigits(client.phoneNumber);
            }

        });
    }

    public onExistingAccountsResetModalContinue(): void {
        this.existingAccountsResetModal.hideModalBox();
        this.didComponentInitiateCurrentAccountRefresh = true;
        this.delegationService.publish(CommandText.AddOwnerToProposal);
    }

    public onExistingAccountsResetModalCancel(): void {
        this.delegationService.publish(CommandText.EnableSaveAndExitButton);
        this.existingAccountsResetModal.hideModalBox();
    }

    private displayWarningModalIfNecessary(): void {
        if (this.doesProposalContainExistingAccounts() && this.haveClientsBeenAdded() &&
            this.featureFlagService.checkExistingAccountsSectionEnabled()) {
                this.existingAccountsResetModal.showModalBox();
        } else {
            this.modifyProposalScenario();
        }
    }

    private doesProposalContainExistingAccounts(): boolean {
        return this.proposal?.scenarios[0]?.accounts?.some((account: Account) => account?.currentAccountNumber) ||
            this.proposalService.getExistingAccountsPickListFromCurrentProposal()?.size > 0;
    }

    private haveClientsBeenAdded(): boolean {
        let hasNewClientBeenAdded: boolean = false;
        const clientIdsFromProposalBackup: number[] = [];
        this.proposalBackup?.clients?.forEach((client) => {
            if (client?.entityType === EntityType.Client) {
                clientIdsFromProposalBackup.push(client?.entityId);
            }
        });

        this.proposal?.clients?.forEach((client) => {
            if (client?.entityType === EntityType.Client) {
                const isClientAlreadyIncluded: boolean = clientIdsFromProposalBackup?.includes(client?.entityId);
                if (!isClientAlreadyIncluded) {
                    hasNewClientBeenAdded = true;
                }
            }
        });
        return hasNewClientBeenAdded;
    }
}
